/*
 *  Document   : inbox.min.css
 *  Author     : RedStar Theme
 *  Description: This style sheet for email template
 */

.inbox .pagination-control,.inbox th.text-right {
	text-align: right
}

.inbox {
	margin-bottom: 20px
}

.inbox .compose-btn {
	padding: 8px 14px
}

.inbox .inbox-content {
	min-height: 400px
}

.inbox .inbox-nav {
	margin: 30px 0;
	padding: 0;
	list-style: none
}

.inbox .inbox-nav>li {
	padding: 0;
	margin: 0
}

.inbox .inbox-nav>li>a {
	text-decoration: none !important;
	display: block;
	padding: 10px 16px;
	border-left: 4px solid transparent
}

.inbox .inbox-nav>li>a:hover {
	background: #eee;
}
.inbox .inbox-nav>li>a .badge {
	float: right;
	margin-top: 1px
}

.inbox .inbox-nav>li.active>a {
	border-left: 4px solid #ed6b75
}

.inbox .inbox-nav>li.divider {
	padding: 0;
	margin: 4px 16px;
	border-top: 1px solid #eef1f5
}

.inbox .inbox-contacts {
	margin: 0 0 30px;
	padding: 0;
	list-style: none
}

.inbox .inbox-contacts>li {
	padding: 0;
	margin: 0
}

.inbox .inbox-contacts>li.divider {
	padding: 0;
	margin: 4px 16px;
	border-top: 1px solid #eef1f5
}

.inbox .inbox-contacts>li>a {
	position: relative;
	display: block;
	padding: 8px 16px;
	color: #3f444a
}

.inbox .inbox-contacts>li>a:hover {
	background: #f1f4f7;
	text-decoration: none
}

.inbox .inbox-contacts>li>a .contact-name {
	display: inline-block;
	padding-left: 5px
}

.inbox .inbox-contacts>li>a .contact-pic {
	width: 30px;
	height: 30px;
	border-radius: 50% !important
}

.inbox .inbox-contacts>li>a .contact-status {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50% !important;
	left: 38px;
	top: 30px
}

.inbox .input-actions .btn {
	margin-left: 10px
}

.inbox .inbox-view-info {
	color: #666;
	padding: 5px 0;
	border-top: solid 1px #eee;
	border-bottom: solid 1px #eee
}

.inbox .inbox-view-info .inbox-author {
	border-radius: 50% !important;
	margin-right: 10px
}

.inbox .inbox-view-info .inbox-info-btn .btn-group {
	float: right;
	margin-top: 5px
}

.inbox .inbox-body,.inbox .inbox-sidebar {
	
	border: 1px solid #e7ecf1;
	border-radius: 4px;
    margin-top: 14px;
}
.btn-prev-next {
    margin-right: 10px;
}

.page-container-bg-solid .inbox .inbox-body,.page-container-bg-solid .inbox .inbox-sidebar
	{
	background: #fff;
	border: 0
}

.inbox .inbox {
	margin-bottom: 0
}

.inbox .tab-content {
	overflow: inherit
}

.inbox .inbox-loading {
	display: none;
	font-size: 22px;
	font-weight: 300
}

.inbox .inbox-header {
	overflow: visible;
	padding-top: 13px;
}

.inbox .inbox-header h1 {
	margin: 0 0 20px;
	color: #666
}

.inbox .pagination-control .pagination-info {
	display: inline-block;
	padding-right: 10px;
	font-size: 14px;
	line-height: 14px
}

.inbox tr {
	color: #4a4848;
	font-size: 15px;
	border-bottom: 1px solid #e8e8e8;
}

.inbox tr label {
	display: inline-block
}

.inbox tr.unread td {
	font-weight: 600;
	vertical-align: middle;
}

.inbox td i.icon-paper-clip {
	top: 2px;
	color: #d8e0e5;
	font-size: 17px;
	position: relative
}
.btn-group>a>i {
    color: #333333;
}

.inbox tr i.icon-star,.inbox tr i.icon-trash {
	cursor: pointer
}

.inbox tr i.icon-star {
	color: #eceef0
}
label.checkbox-custom.check-success {
    margin-bottom: 0px;
    display: table;
}

.inbox tr i.icon-star:hover,.inbox tr i.inbox-started {
	color: #f36a5a
}

.inbox .table td,.inbox .table th {
	border: none;
	vertical-align: middle;
}

.inbox .table th {
	background: #eef4f7;
	border-bottom: solid 5px #fff
}

.inbox th label.inbox-select-all {
	color: #828f97;
	font-size: 13px;
	padding: 1px 4px 0
}

.inbox td.text-right {
	width: 100px;
	text-align: right
}

.inbox td.inbox-small-cells {
	width: 10px
}

.inbox .table-hover tbody tr:hover>td,.inbox .table-hover tbody tr:hover>th,.inbox .table-striped tbody>tr:nth-child(odd)>td,.inbox .table-striped tbody>tr:nth-child(odd)>th
	{
	background: #f8fbfd;
	cursor: pointer
}

.inbox .table-hover tbody tr:hover>td,.inbox .table-hover tbody tr:hover>th
	{
	background: #eef4f7
}

.inbox .inbox-drafts {
	padding: 8px 0;
	text-align: center;
	border-top: solid 1px #eee;
	border-bottom: solid 1px #eee
}

.inbox-view-header {
	margin-bottom: 20px
}

.inbox-view-header h1 {
	color: #666;
	font-size: 22px;
	line-height: 24px;
	margin-bottom: 0 !important
}

.inbox-view-header h1 a {
	top: -2px;
	color: #fff;
	cursor: pointer;
	font-size: 13px;
	padding: 2px 7px;
	line-height: 16px;
	position: relative;
	background: #b0bcc4;
	display: inline-block
}

.inbox-view-header h1 a:hover {
	background: #aab5bc;
	text-decoration: none
}

.inbox-view-header i.icon-print {
	color: #94a4ab;
	cursor: pointer;
	font-size: 14px;
	display: inline-block;
	padding: 6px 8px !important;
	background: #edf1f4 !important
}

.inbox-view-header i.icon-print:hover {
	background: #e7ebef !important
}

.inbox-view-info strong {
	color: #666;
	margin: 0 10px 0 5px
}

.inbox-view {
	color: #666;
	padding: 10px 0 0
}

.inbox-view a {
	color: #169ce9
}

.inbox-attached {
	line-height: 16px
}

.inbox-attached a {
	margin: 0 2px
}

.inbox-attached img {
	height: auto;
	max-width: 250px;
	margin-bottom: 5px
}

.inbox-attached span {
	margin-right: 3px
}

.inbox-attached strong {
	color: #555;
	display: block;
	font-size: 13px
}

.inbox-attached  {
	margin-bottom: 25px
}

.inbox-attached .mg-bottom-15 {
	margin-bottom: 15px
}

.inbox-compose {
	margin-top: 1px;
	border: 1px solid #eee
}

.inbox-compose-btn {
	padding: 12px;
	background: #f0f6fa
}

.inbox-compose-attachment {
	padding: 8px
}

.inbox-compose-attachment .btn {
	padding: 4px 10px
}

.inbox-compose .inbox-form-group {
	margin-bottom: 0;
	position: relative;
	border-bottom: solid 1px #eee
}

.inbox-compose .controls {
	margin-left: 85px
}

.inbox-compose .inbox-form-group>label {
	width: 80px;
	float: left;
	color: #979797;
	text-align: right
}

.inbox-compose .controls>input {
	border: none !important
}

.inbox-compose .controls-to {
	padding-right: 55px
}

.inbox-compose .controls-bcc,.inbox-compose .controls-cc {
	padding-right: 20px
}

.inbox-compose .inbox-form-group a.close {
	top: 13px;
	right: 10px;
	position: absolute
}

.inbox-compose .mail-to .inbox-cc-bcc {
	display: inline-block;
	top: 7px;
	right: 10px;
	color: #979797;
	font-size: 14px;
	cursor: pointer;
	position: absolute
}

.inbox-compose .mail-to .inbox-bcc {
	margin-left: 5px
}

.inbox-compose .mail-to inbox-bcc:hover,.inbox-compose .mail-to inbox-cc:hover
	{
	color: #777
}

.inbox-compose .wysihtml5 {
	padding: 0 !important;
	margin: 0 !important;
	border: 0 !important
}

.inbox-compose .wysihtml5-sandbox {
	padding: 0 !important;
	margin: 5px 0 0 !important;
	display: block !important;
	border: 0 !important;
	width: 100% !important;
	border-left: none;
	border-right: none
}

.inbox-compose .wysihtml5-toolbar {
	border: 0;
	border-bottom: 1px solid #eee
}

.inbox-compose .wysihtml5-toolbar>li {
	height: 34px;
	margin-right: 0;
	margin-bottom: 0
}

.inbox-compose .wysihtml5-toolbar>li>a,.inbox-compose .wysihtml5-toolbar>li>div>a
	{
	background: #fff !important;
	border-color: #fbfbfb!important
}

.inbox-compose .wysihtml5-toolbar>li>a:hover,.inbox-compose .wysihtml5-toolbar>li>div>a:hover
	{
	background: #f1f1f1 !important;
	border-color: #f1f1f2 !important;
	border-radius: 0
}

.inbox-compose .wysihtml5-toolbar .dropdown.open .dropdown-toggle,ul.wysihtml5-toolbar a.btn.wysihtml5-command-active
	{
	background: #eee !important
}
.mail-option {
    padding: 0px 0px 20px 20px;
}
.label.mail-counter-style {
    padding: 2px 6px 4px !important;
    font-size: 10px;
}
.label.mail-label {
    padding: 3px 6px !important;
    font-size: 11px;
}
.table-inbox .avatar img, .table-inbox .avatar spanimg {
    width: 30px;
    height: 30px;
}
.table-inbox .avatar img, .table-inbox .avatar span {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
}
.table-inbox .avatar span {
    /* background: #929292; */
    text-align: center;
    color: #fff;
}
.inbox-sidebar {
    background: #F2F0F5;
}
.inbox .inbox-nav>li>a {
    color: #333333;
}
.inbox-sidebar {
    background: #E8EAED;
}
.nav-stacked.inbox-divider li {
    display: block;
}
.nav-stacked.inbox-divider {
    display: block;
}
.nav-pills>li>h4 {
    text-align: center;
    background: #ddd;
    height: 39px;
    padding: 10px;
}
ul.labels-info li a {
    color: #6a6a6a;
    border-radius: 0;
    border-right: 2px solid #f5f5f5;
    display: block;
}
.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}
.nav.nav-pills.nav-stacked.labels-info p {
    margin-bottom: 0;
    padding: 0 22px;
    color: #9d9f9e;
    font-size: 11px;
    margin-top: 0px;
}
p.date {
    text-align: right;
    padding-right: 20px;
}
.mail-heading {
    padding: 10px 0px 0px 0px;
}
.view-mail{
    padding: 20px;
}
.mail-sender {
    border-bottom: 1px solid #EFF2F7;
    padding: 20px;
}
.attachment-mail {
    padding: 20px;
}
.attachment-mail>ul {
    list-style-type: none;
}
.btn-group .btn {
    text-transform: initial;
}

 .mail-box-info .mail-list {
    margin-top: 30px;
}

.mail-list {
    overflow-x: hidden;
    overflow-y: auto;
    background: #ffffff;
}
.view-message a {
    color: black;
    text-decoration: none;
}
.compose-mail {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 20px;
}
.compose-mail .modal-title{
    width: 100%;
}

/* .compose-mail .form-group {
    border: 1px solid #eaebee;
    display: inline-block;
    width: 100%;
}
.compose-mail .form-group label {
    line-height: 34px;
    width: 70px;
    float: left;
    padding-left: 10px;
    margin-bottom: 0;
    background: #eaebee;
    margin-right: 10px;
} */
.compose-mail .email-form {
    border: 1px solid #eaebee;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}
.compose-mail .email-form label {
    line-height: 34px;
    width: 70px;
    float: left;
    padding-left: 10px;
    margin-bottom: 0;
    background: #eaebee;
    margin-right: 10px;
}
.compose-mail .itemField, .compose-mail .itemField:focus {
    border: none;
    padding: 0;
    width: calc(92% - 70px);
    float: left;
}
.compose-mail .compose-options {
    color: #979797;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    position: absolute;
    right: 30px;
    top: 30px;
}
.compose-options a {
    color: #5C6E8A;
}
.compose-editor {
    padding-top: 50px;
}
.btn-sm {
    margin-right: 8px;
}
.mail-option .todo-check input[type="checkbox"] {
    margin-top: 10px;
}
.btn {
    padding: 8px 25px 7px;
}
.btn-group.group-padding {
    padding-left: 40px;
}
.bg-info{
    color: #fff;
    background-color: #6bd3f3;
}

.bg-success{
    color: #fff;
    background-color: #53d192;
}

.bg-warning{
    color: #fff;
    background-color: #ffd200;
}

.bg-primary{
    color: #fff;
    background-color: #9c78cd;
}


.bg-danger{
    color: #fff;
    background-color: #e55957;
}

.bg-dark{
    color: #fff;
    background-color: #2b2b2c;
}
i.fa.fa-angle-down.downcolor {
    color: white;
}
.media-left, .media>.pull-left {
    padding-right: 10px;
}