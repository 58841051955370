@charset "UTF-8";
/*
 *  Document   : theme_style.css
 *  Author     : RedStar Theme
 *  Description: The main theme stylesheet of the template
 *
 *  Structure (with shortcodes):

 		1. GLOBLE STYLE
		2. BODY
		3. HEADER
		4. SIDE MENU
		5. SEARCH BAR
		6. NAVIGATION
		7. PANEL
		8. PAGE CONTENT
		9. FORM
		10. USER PANEL STYLE
		11. MODAL WINDOW
		12. CHAT PANEL
		13. CARD BOX STYLE
		14. PROFILE
		15. WIZARD
		16. TABLE
		17. BUTTON
		18. LABEL
		19. CALENDAR
		20. DROP DOWN STYLE
		21. CHECKBOX & RADIO
		22. ICON BUTTON
		23. VALIDATION ICONS
		24. NOTE
		25. PAGINATION
		26. ACCORDION
		27. TAB
		28. STATE OVERVIEW
		29. BORDER BOX
		30. RATING BUTTON
		31. TO DO LIST
		32. BADGE
		33. SCROLL TO TOP
		34. FOOTER
		35. FEED
 */
/***********************************************************************

						[1. GLOBLE STYLE ]

***********************************************************************/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Poppins,sans-serif;
    font-weight: 300;
}
h1 {
    font-size: 36px !important;
}
h2 {
    font-size: 30px !important;
}
h3 {
    font-size: 23px !important;
}
h4 {
    font-size: 18px !important;
}
h5 {
    font-size: 14px !important;
}
h6 {
    font-size: 13px !important;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    color: #444
}
h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
    padding-top: 10px;
    padding-bottom: 10px
}
.h4, .h5, .h6, h4, h5, h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.alert,
.badge,
.btn-group>.btn,
.btn.btn-link:hover,
.btn,
.icon-btn,
.label,
.note,
.panel,
.overview-panel {
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .18)
}
.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}
.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.badge,
.input-inline {
    vertical-align: middle
}
hr{
    margin: 20px 0
}
a {
    text-shadow: none;
    color: #337ab7;
}
a:hover {
    cursor: pointer;
    color: #23527c
}
a:active,
a:focus,
a:hover {
    outline: 0;
    text-decoration: none;
}
hr {
    border: 0;
    border-top: 1px solid #eee;
    border-bottom: 0
}
code {
    border: 1px solid #e1e1e1;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, .1)
}
.bold {
    font-weight: 700!important
}
.sbold {
    font-weight: 600!important
}
.thin {
    font-weight: 500!important
}
.uppercase {
    text-transform: uppercase!important
}
.border {
    border: 1px solid red
}
.inline {
    display: inline
}
.inline-block {
    display: inline-block
}
.circle {
    border-radius: 25px!important
}
.circle-right {
    border-radius: 0 25px 25px 0!important
}
.circle-left {
    border-radius: 25px 0 0 25px!important
}
.circle-bottom {
    border-radius: 0 0 25px 25px!important
}
.circle-top {
    border-radius: 25px 25px 0 0!important
}
.rounded {
    border-radius: 50%!important
}
.display-hide,
.display-none {
    display: none
}
.hidden {
    display: none!important
}
.no-margin {
    margin: 0
}
.mg-bottom-10 {
    margin-bottom: 10px !important
}
.margin-top-20 {
    margin-top: 20px!important
}
.margin-right-10 {
    margin-right: 10px!important
}
.visible-ie8 {
    display: none
}
.ie8 .visible-ie8 {
    display: inherit!important
}
.visible-ie9 {
    display: none
}
.ie9 .visible-ie9 {
    display: inherit!important
}
.hidden-ie8 {
    display: inherit
}
.ie8 .hidden-ie8 {
    display: none!important
}
.hidden-ie9 {
    display: inherit
}
.ie9 .hidden-ie9 {
    display: none!important
}
.m-b-10 {
    margin-bottom: 10px !important;
}
.p-t-10{
	padding-top: 10px !important;
}
.p-t-20{
	padding-top: 20px !important;
}
.p-r-20{
	padding-right: 20px !important;
}
.p-b-20{
	padding-bottom: 20px !important;
}
.p-l-20{
	padding-left: 20px !important;
}
.p-r-100{
	padding-right: 100px !important;
}
.m-r-20{
	margin-right: 20px !important;
}
.m-b-20{
	margin-bottom: 20px !important;
}
.m-t-20{
	margin-top: 20px !important;
}
.m-l-20{
	margin-left: 20px !important;
}
.m-r-100{
	margin-right: 100px !important;
}
.margin-0 {
    margin: 0!important;
}
.input-small {
    width: 145px!important
}
.input-inline {
    width: auto
}
.font-bold {
    font-weight: bold;
}
.label-rouded {
    border-radius: 50px;
}
.text-center{
	text-align: center;
}
/******* Colors Codes********/

.text-purple {
    color: #9675ce;
}
.text-white {
    color: #ffffff;
}
.text-success {
    color: #36c6d3
}
.text-info {
    color: #659be0
}
.text-warning {
    color: #F1C40F
}
.text-danger {
    color: #ed6b75
}
.headerBadgeColor1{
	background-color: #C46210 ;
}
.headerBadgeColor2{
	 background-color: #673ab7 ;
}
.orange-bgcolor {
    background-color: #C46210 !important;
}
.purple-bgcolor,
.label.purple-bgcolor {
    background-color: #673ab7;
}
.blue-bgcolor,
.btn.blue-bgcolor {
    background-color: #2196F3;
    color: #fff;
}
.cyan-bgcolor {
    background-color: #36C6D3;
    color: #fff;
}
.deepPink-bgcolor,
.label.deepPink-bgcolor {
    background-color: #e91e63 !important;
    color: #fff !important;
}
.bg-warning{
    color: #fff;
    background-color: #ffd200;
}
.bg-success {
    background: #5FC29D;
    color: #fff;
}

.bg-orange {
    background: #E67D21;
    color: #fff;
}
.bg-blue {
    background: #3598dc;
    color: #fff;
}

.bg-purple {
    background: #8E44AD;
    color: #fff;
}
.bg-danger{
    color: #fff;
    background-color: #e55957;
}
.red {
	color: #fff;
    background-color: #F44336;
}
.pink {
    background-color: #FF1493;
    color: #fff;
}
.yellow {
    background-color: #f1c500;
    color: #fff;
}
.green-color{
	color: green;
}
.state-overview {
    color: #fff;
}
.overview-panel {
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.badge,
.btn {
    font-weight: 600;
    text-transform: uppercase
}
.list-separated {
    margin-top: 10px;
    margin-bottom: 15px
}
.list-separated>div:last-child {
    border-right: 0
}
/****** Fontawasome style  *********/

[class*=" fa-"]:not(.fa-stack),
[class*=" glyphicon-"],
[class*=" icon-"],
[class^=fa-]:not(.fa-stack),
[class^=glyphicon-],
[class^=icon-] {
    display: inline-block;
    line-height: 14px;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
}
li [class*=" fa-"],
li [class*=" glyphicon-"],
li [class*=" icon-"],
li [class^=fa-],
li [class^=glyphicon-],
li [class^=icon-] {
    display: inline-block;
    width: 1.25em;
    text-align: center
}
li [class*=" glyphicon-"],
li [class^=glyphicon-] {
    top: 2px
}
li [class*=" icon-"],
li [class^=icon-] {
    top: 1px;
    position: relative
}
li [class*=" fa-"].icon-large,
li [class*=" glyphicon-"].icon-large,
li [class*=" icon-"].icon-large,
li [class^=fa-].icon-large,
li [class^=glyphicon-].icon-large,
li [class^=icon-].icon-large {
    width: 1.5625em
}
.fa-item {
    font-size: 14px;
    padding: 15px 10px 15px 20px
}
.fa-item i {
    font-size: 16px;
    display: inline-block;
    width: 20px;
    color: #333
}
.fa-item:hover {
    cursor: pointer;
    background: #eee
}
/********* Simple line icon  style ***************/

.sl-iconsdemo .item-box {
    display: inline-block;
    font-size: 16px;
    margin: 0 -.22em 1em 0;
    padding-left: 1em;
    width: 100%
}
.sl-iconsdemo .item-box .item {
    background-color: #fff;
    color: #33383e;
    border-radius: 8px;
    display: inline-block;
    padding: 10px;
    width: 100%
}
.sl-iconsdemo .item-box .item span {
    font-size: 22px
}
.badge-danger,
.badge-default,
.badge-info,
.badge-primary,
.badge-success,
.badge-warning,
.btn.white:not(.btn-outline).active,
.btn.white:not(.btn-outline):active,
.open>.btn.white:not(.btn-outline).dropdown-toggle {
    background-image: none
}
.alert>p {
    margin: 0
}
.alert.alert-borderless {
    border: 0
}
.alert {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    border-width: 0
}
.margin-top-10 {
    margin-top: 10px;
}
.full-width {
    float: left;
    width: 100%;
}
.hide{
	display: none!important;
}
.img-circle {
    border-radius: 50%;
}
.pull-right {
    float: right!important;
}
/******************************************************************************************

						 		[ 2. BODY ]

*******************************************************************************************/

body {
    background-color:#414141;
    padding: 0!important;
    margin: 0!important;
    font-family: Poppins,sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7;
    color: rgba(0,0,0,.87);
}
/* ***********************************************************************

							    [ 3. PAGE HEADER ]

**************************************************************************/
.navbar-fixed-top {
    position: fixed;
    right: 0px;
    left: 0px;
    top: 0px;
}
.page-header.navbar {
    background: #193650;
}
.page-header.navbar .page-logo {
    float: left;
    display: block;
    width: 235px;
    height: 60px;
    padding: 10px 20px 0px 30px;
    background: #222c3c;
}
.logo-icon{
	font-size: 35px !important;
	padding-right: 10px;
    padding-top: 13px;
    float: left;
}
.fa-rotate-45 {
    -webkit-transform: rotate(330deg) !important;
    -moz-transform: rotate(330deg) !important;
    -ms-transform: rotate(330deg) !important;
    -o-transform: rotate(330eg) !important;
    transform: rotate(330deg) !important;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle>i {
    color: #1E2629;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle .badge.badge-default {
    background-color: #3881b3;
    color: #fff;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle:hover,
.page-header.navbar .top-menu .navbar-nav>li.dropdown.open .dropdown-toggle {
    background-color: rgba(0, 0, 0, 0);
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-toggle:hover>i,
.page-header.navbar .top-menu .navbar-nav>li.dropdown.open .dropdown-toggle>i {
    color: #8c8c8c;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu {
    border-color: #e7eaf0;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu:after {
    border-bottom-color: #eaedf2;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external {
    background: #EFF0F4;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>h3 {
    color: #555;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>a {
    color: #337ab7;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>a:hover {
    color: #23527c;
    text-decoration: none;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li>a {
    border-bottom: 1px solid #EFF2F6!important;
    color: #888;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li>a:hover {
    background: #f8f9fa;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li>a .time {
    background: #f1f1f1;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li>a:hover .time {
    background: #e4e4e4;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-toggle>.circle {
    background-color: #3881b3;
    color: #fff;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-toggle>.corner {
    border-color: transparent transparent transparent #3881b3;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox .dropdown-menu .dropdown-menu-list .subject .from {
    color: #5b9bd1;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>.langname,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>.username,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-toggle>i {
    color: #1E2629;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list .progress {
    background-color: #dfe2e9;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user>.dropdown-menu {
    width: 195px;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu {
    background: #3f4f62;
    border: 0;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu:after {
    border-bottom-color: #3f4f62;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu>li.external {
    background: #2f3b49;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu>li.external>h3 {
    color: #adbaca;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu>li.external>a:hover {
    color: #5496cf;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu .dropdown-menu-list>li a,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu.dropdown-menu-default>li a {
    color: #bcc7d4;
    border-bottom: 1px solid #4b5e75!important;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu .dropdown-menu-list>li a>i,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu.dropdown-menu-default>li a>i {
    color: #9dadc0;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu .dropdown-menu-list>li a:hover,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu.dropdown-menu-default>li a:hover {
    background: #47596e;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu.dropdown-menu-default>li a {
    border-bottom: 0!important;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu.dropdown-menu-default>li.divider {
    background: #4b5e75;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list>li>a .time {
    background: #354353;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list>li>a:hover .time {
    background: #2b3643;
}
.dropdown-quick-sidebar-toggler a {
    padding: 0px !important;
    top: 10px;
}
.page-header.navbar .search-form {
    background: transparent;
    border: 0;
    color: #B4BCC8;
}
.page-header.navbar input.search-form:focus {
    outline: 0;
}
.page-header.navbar .search-form.open,
.page-header.navbar .search-form:hover {
    background: transparent;
}
.page-header.navbar .search-form .input-group .form-control {
    color: #959fad;
}
.page-header.navbar .search-form .input-group .form-control::-moz-placeholder {
    color: #929cab;
    opacity: 1;
}
.page-header.navbar .search-form .input-group .form-control:-ms-input-placeholder {
    color: #929cab;
}
.page-header.navbar .search-form .input-group .form-control::-webkit-input-placeholder {
    color: #929cab;
}
.page-header.navbar .search-form .input-group .input-group-btn .btn.submit>i {
    color: #ffffff;
}
.page-header.navbar .menu-toggler>span,
.page-header.navbar .menu-toggler>span:after,
.page-header.navbar .menu-toggler>span:before,
.page-header.navbar .menu-toggler>span:hover,
.page-header.navbar .menu-toggler>span:hover:after,
.page-header.navbar .menu-toggler>span:hover:before {
    background: #a7b5c6;
}
.page-header.navbar .menu-toggler.th-toggle-exit>span {
    background-color: transparent!important;
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu {
    box-shadow: 5px 5px rgba(63, 79, 98, .2);
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3,
.page-header.navbar .hor-menu .navbar-nav>li>a {
    color: #c6cfda;
}
.page-header.navbar .hor-menu .navbar-nav>li>a>i {
    color: #c2cad8;
}
.page-header.navbar .hor-menu .navbar-nav>li.open>a,
.page-header.navbar .hor-menu .navbar-nav>li:hover>a,
.page-header.navbar .hor-menu .navbar-nav>li>a:hover {
    color: #d5dce4;
    background: #3f4f62!important;
}
.page-header.navbar .hor-menu .navbar-nav>li.open>a>i,
.page-header.navbar .hor-menu .navbar-nav>li:hover>a>i,
.page-header.navbar .hor-menu .navbar-nav>li>a:hover>i {
    color: #889bb2;
}
.page-header.navbar .hor-menu .navbar-nav>li.active>a,
.page-header.navbar .hor-menu .navbar-nav>li.current>a {
    color: #fff;
    background: #3881b3;
}
.page-header.navbar .hor-menu .navbar-nav>li.active>a>i,
.page-header.navbar .hor-menu .navbar-nav>li.current>a>i {
    color: #c2cad8;
}
.page-header.navbar .hor-menu .navbar-nav>li.active .selected,
.page-header.navbar .hor-menu .navbar-nav>li.current .selected {
    border-top: 6px solid #3881b3;
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    box-shadow: 5px 5px rgba(63, 79, 98, .2);
    background: #3f4f62;
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a>i {
    color: #c6cfda;
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li:hover>a {
    color: #f1f3f6;
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li:hover>a>i {
    color: #f1f3f6;
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover {
    color: #f1f3f6;
    background: #47596e;
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a:hover>i,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.active>a>i,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a:hover>i,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.current>a>i {
    color: #f1f3f6;
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li.divider {
    background-color: #495c72;
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-submenu>a:after {
    color: #c6cfda;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.mega-menu-dropdown>.dropdown-menu {
    box-shadow: 5px 5px rgba(102, 102, 102, .1);
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3 {
    color: #666;
}
.mega-menu-dropdown .owl-controls {
    display: none!important;
}
.classic-menu-dropdown .dropdown-menu, .mega-menu-dropdown .dropdown-menu {
    right: auto;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li>a {
    color: #c6cfda;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li>a>i {
    color: #788ea8;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li:hover>a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li>a:hover {
    color: #d5dce4;
    background: #3f4f62;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li:hover>a>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li>a:hover>i {
    color: #889bb2;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.open>a {
    color: #333!important;
    background: #fff!important;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.open>a>i {
    color: #333!important;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.active>a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.active>a:hover,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.current>a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.current>a:hover {
    color: #fff;
    background: #3881b3;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.active>a:hover>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.active>a>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.current>a:hover>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li.current>a>i {
    color: #788ea8;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu {
    box-shadow: 5px 5px rgba(102, 102, 102, .1);
    background: #fff;
    border: 1px solid #f2f2f2;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li>a {
    color: #000;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li>a>i {
    color: #888;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li:hover>a {
    color: #000;
    background: #f5f5f5;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li:hover>a>i {
    color: #666;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.active>a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.active>a:hover,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.current>a,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.current>a:hover {
    color: #000;
    background: #f5f5f5;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.active>a:hover>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.active>a>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.current>a:hover>i,
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.current>a>i {
    color: #666;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li .dropdown-menu li.divider {
    background-color: #f5f5f5;
}
.page-header.navbar .hor-menu.hor-menu-light .navbar-nav>li>.dropdown-menu {
    border-top: 0;
}
.page-header.navbar .menu-toggler>span,
.sidemenu-container .sidebar-toggler>span,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span {
    outline: 0 !important
}
.page-header.navbar,
.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .progress .progress-bar {
    box-shadow: none
}
.page-header.navbar {
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;
    min-height: 50px;
    filter: none;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.page-header-inner {
    float: left;
    width: 100%;
}
.navbar-custom {
    background: #f1efef;
    width: 100%;
    text-align: center;
    height: 60px;
}
.page-header.navbar.navbar-fixed-top,
.page-header.navbar.navbar-static-top {
    z-index: 8999;
}
.sidemenu-closed.sidemenu-closed-hidelogo .page-header.navbar .page-logo {
    padding: 0
}
.page-header.navbar .page-logo>.logo-image,
.page-header.navbar .page-logo>a {
    display: inline-block;
    float: left
}
.page-header.navbar .page-logo .logo-default {
    margin: 7px 0 0;
    height: 34px;
}
.page-header.navbar .page-logo .logo-mini {
    display: none;
    margin-left: 5px
}
.page-header.navbar .page-logo .text-logo {
    padding-left: 20px;
    padding-top: 12px
}
.page-logo a {
    text-decoration: none;
    color: #fff;
    font-size: 25px;
}
.navbar-nav>li>a,
.navbar-nav>li>a.sidebar-toggler,
.navbar-nav>li>a.fullscreen-btn {
    padding: 20px 10px 20px 15px;
    line-height: 20px;
}
.page-header.navbar .search-form {
    display: inline-block;
    position: relative;
    width: 50px;
    float: left;
    transition: all .6s;
    margin-top: 10px;
}
.page-header.navbar .search-form .input-group .form-control {
    height: 50px;
    border: 0;
    background: 0 0 !important;
    font-size: 13px;
    padding-left: 0;
    margin-left: 12px;
    text-indent: -150000px
}
.page-header.navbar .search-form .input-group .form-control:hover {
    cursor: pointer
}
.page-header.navbar .search-form .input-group .input-group-btn {
    height: 50px
}
.page-header.navbar .search-form .input-group .input-group-btn .btn.submit {
    margin-left: -24px;
    padding: 0;
    width: 50px;
    background: 0 0;
    margin-top: 4px;
    display: block
}
.page-header.navbar .search-form .input-group .input-group-btn .btn.submit>i {
    font-size: 15px
}
.page-header.navbar .search-form.open {
    transition: all .6s;
    width: 200px !important
}
.page-header.navbar .search-form.open .input-group .form-control {
    text-indent: 0
}
.page-header.navbar .search-form.open .input-group .form-control:hover {
    cursor: text
}
.page-header.navbar .search-form.open .input-group .input-group-btn .btn.submit {
    margin-left: 0
}
form.search-form-opened {
    float: left;
    width: 100%;
    max-width: 200px;
    background-color: transparent;
    color: #fff;
    border-radius: 30px;
    margin: 10px 0 0 10px;
    border: 1px solid #bdbdbd;
}
form.search-form-opened .btn.submit {
    margin: 0;
    color: #1E2629;
}
.search-form-opened .input-group-btn a{
	padding: 12px 12px 7px;
}
.search-form-opened input[type="text"] {
    background-color: transparent;
    border: 0;
    color: #1E2629;
    padding: 7px 12px;
}
.page-header.navbar .menu-toggler {
    cursor: pointer;
    opacity: .7;
    filter: alpha(opacity=70);
    display: block;
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -ms-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    background: none;
}
.page-header.navbar .menu-toggler>span:hover,
.page-header.navbar .menu-toggler>span:hover:after,
.page-header.navbar .menu-toggler>span:hover:before {
    background: #a7b5c6;
}
.page-header.navbar .menu-toggler>span,
.page-header.navbar .menu-toggler>span:after,
.page-header.navbar .menu-toggler>span:before {
    display: inline-block;
    width: 19px;
    height: 1px;
    position: relative;
    top: -10px;
    transition: all ease .3s
}
.menu-toggler i.icon-menu{
	color: #808040;
	font-size: 18px;
}
.page-header.navbar .menu-toggler>span:after,
.page-header.navbar .menu-toggler>span:before {
    position: absolute;
    left: 0;
    content: ''
}
.page-header.navbar .menu-toggler>span:before {
    top: 6px
}
.page-header.navbar .menu-toggler>span:after {
    top: -6px
}
.page-header.navbar .menu-toggler.th-toggle-exit>span {
    background-color: transparent !important
}
.page-header.navbar .menu-toggler.th-toggle-exit>span:after {
    -webkit-transform: translateY(6px) rotateZ(45deg);
    -moz-transform: translateY(6px) rotateZ(45deg);
    -ms-transform: translateY(6px) rotateZ(45deg);
    -o-transform: translateY(6px) rotateZ(45deg);
    transform: translateY(6px) rotateZ(45deg)
}
.page-header.navbar .menu-toggler.th-toggle-exit>span:before {
    -webkit-transform: translateY(-6px) rotateZ(-45deg);
    -moz-transform: translateY(-6px) rotateZ(-45deg);
    -ms-transform: translateY(-6px) rotateZ(-45deg);
    -o-transform: translateY(-6px) rotateZ(-45deg);
    transform: translateY(-6px) rotateZ(-45deg)
}
.page-header.navbar .menu-toggler:hover {
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -ms-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    opacity: 1;
    filter: alpha(opacity=100);
}
.page-header.navbar .menu-toggler:focuse{
	background: none;
}
/* .page-header.navbar .menu-toggler.sidebar-toggler {
    float: right;
    margin: 15.5px 0 0
} */
.page-header.navbar .menu-toggler.responsive-toggler {
    display: none;
    float: right;
    margin: 23px 13px 0
}
.page-header.navbar .top-menu {
    margin: 0;
    padding: 0;
    float: right
}
.page-header.navbar .top-menu .navbar-nav {
    padding: 0;
    margin-right: 20px;
    display: block
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown {
    margin: 0;
    padding: 5px 8px;
    display: inline-block;
    float: left;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown:last-child {
    padding-right: 0;
    padding-left: 3px;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle {
    margin: 0;
    padding: 16px 10px 9px 0;
    display: block;
    position: relative;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle:last-child {
    padding-right: 0
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle>i {
    font-size: 17px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle>i.glyphicon {
    font-size: 16px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle>.badge {
    font-family: Poppins,sans-serif;
    position: absolute;
    top: 10px;
    right: 0px;
    font-weight: 300;
    padding: 3px 6px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown>.dropdown-toggle:focus {
    background: 0 0
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu {
    margin-top: 3px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #eee;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, .2);
    content: ''
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block !important;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    content: ''
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown .dropdown-menu>li>a {
    color: #555;
    font-size: 13px;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu {
    min-width: 160px;
    max-width: 355px;
    width: 355px;
    z-index: 9995
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external {
    display: block;
    overflow: hidden;
    padding: 15px;
    letter-spacing: .5px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -ms-border-radius: 4px 4px 0 0;
    -o-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>h3 {
    margin: 0;
    padding: 0;
    float: left;
    font-size: 13px;
    display: inline-block;
    font-size: 18px !important;
    line-height: 20px;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>a {
    display: inline-block;
    padding: 0;
    background: 0 0;
    clear: inherit;
    font-size: 13px;
    font-weight: 300;
    position: absolute;
    right: 10px;
    border: 0;
    margin-top: -1px
}
.page-bar:after,
.page-container:after,
.page-footer:after,
.sidemenu-container .sidemenu>li.sidebar-search-wrapper:after,
.sidemenu-container .sidemenu>li.sidebar-toggler-wrapper:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-search-wrapper:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-toggler-wrapper:after {
    clear: both
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external>a:hover {
    text-decoration: none
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list {
    padding-right: 0 !important;
    padding-left: 0;
    list-style: none;
    overflow-y: auto;
    height: 260px;
    width: auto;
}

.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li>a {
    display: block;
    clear: both;
    font-weight: 300;
    line-height: 20px;
    white-space: normal;
    font-size: 13px;
    padding: 8px 15px 8px;
    text-shadow: none
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li>a:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    text-decoration: none
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu .dropdown-menu-list>li:first-child a {
    border-top: none
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details {
    overflow: hidden
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon {
    margin-right: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon i {
    margin-right: 2px;
    margin-left: 1px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon .badge {
    right: 15px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .time {
    float: right;
    max-width: 75px;
    font-size: 11px;
    font-weight: 400;
    opacity: .7;
    filter: alpha(opacity=70);
    text-align: right;
    padding: 1px 5px;
    margin: 7px 0px;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .photo {
    float: left;
    margin: 0 6px 6px 0
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .photo img {
    height: 40px;
    width: 40px;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
    border-radius: 50% !important
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .subject {
    display: block;
    margin-left: 46px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .subject .from {
    font-size: 13px;
    font-weight: 600
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .subject .time {
    font-size: 12px;
    font-weight: 400;
    opacity: .5;
    filter: alpha(opacity=50);
    float: right
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-inbox>.dropdown-menu .dropdown-menu-list>li .message {
    display: block !important;
    font-size: 12px;
    line-height: 1.3;
    margin-left: 46px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .task {
    margin-bottom: 5px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .task .desc {
    font-size: 13px;
    font-weight: 300
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .task .percent {
    float: right;
    font-weight: 600;
    display: inline-block
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-tasks .dropdown-menu .dropdown-menu-list>li .progress {
    display: block;
    height: 8px;
    margin: 8px 0 2px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle {
    padding: 13px 6px 12px 8px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle>.username {
    display: inline-block;
    font-size: 13px;
    font-weight: 300
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle>img {
    float: left;
    margin-top: -2px;
    margin-right: 5px;
    height: 29px;
    display: inline-block
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-toggle>i {
    display: inline-block;
    margin: 0;
    font-size: 13px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu {
    width: 175px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu>li>a {
    font-size: 14px;
    font-weight: 300
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu>li>a i {
    width: 15px;
    display: inline-block;
    margin-right: 9px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-user .dropdown-menu>li>a .badge {
    margin-right: 10px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-language {
    padding-left: 0;
    padding-right: 0;
    margin: 0
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle {
    padding: 16px 3px 13px 7px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>img {
    margin-bottom: 2px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-toggle>i {
    font-size: 14px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-menu>li>a {
    font-size: 13px
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-language>.dropdown-menu>li>a>img {
    margin-bottom: 2px;
    margin-right: 5px
}
.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu:before {
    border-left: none;
    border-right: none
}
.page-header.navbar .top-menu .navbar-nav li.dropdown-dark .dropdown-menu .dropdown-menu-list>li.external a {
    background: 0 0 !important;
    border: none !important
}
.page-header.navbar .container {
    position: relative
}
.page-boxed .page-header>.page-header-inner {
    display: block;
}
.page-header.navbar .hor-menu {
    margin: 0;
    display: inline-block;
}
.page-header.navbar .hor-menu .navbar-nav {
    min-height: 50px;
    position: static;
    display: block;
}
.page-header.navbar .hor-menu .navbar-nav.navbar-right .dropdown-menu {
    left: auto;
    right: 0
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown {
    position: relative;
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown.mega-menu-full {
    position: static;
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu {
    left: auto;
    width: auto
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content {
    font-family: Poppins,sans-serif;
    padding: 15px;
    margin: 0
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content.mega-menu-responsive-content {
    padding: 10px 18px 10px 45px
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu {
    padding: 0;
    margin: 0
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu:last-child {
    border-right: 0
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li {
    margin: 0 !important;
    list-style: none
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>h3 {
    margin-top: 5px;
    padding-left: 6px;
    font-size: 15px;
    font-weight: 400
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a {
    display: block;
    white-space: normal;
    font-family: Poppins,sans-serif;
    padding: 7px;
    margin: 0;
    font-size: 14px;
    font-weight: 300
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a:hover {
    text-decoration: none
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a.iconify {
    padding: 7px 7px 7px 30px
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a.iconify>i {
    position: absolute;
    top: auto !important;
    margin-left: -24px;
    font-size: 15px;
    margin-top: 3px !important
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a .badge,
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown>.dropdown-menu .mega-menu-content .mega-menu-submenu li>a .label {
    margin-left: 5px
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown.mega-menu-full .dropdown-menu {
    left: 20px;
    right: 20px
}
.page-header.navbar .hor-menu .navbar-nav>li.mega-menu-dropdown:hover>.dropdown-menu {
    display: block
}
.page-header.navbar .hor-menu .navbar-nav>li.menu-dropdown .dropdown-menu:after,
.page-header.navbar .hor-menu .navbar-nav>li.menu-dropdown .dropdown-menu:before {
    display: none !important
}
.page-header.navbar .hor-menu .navbar-nav>li>a {
    font-size: 14px;
    font-weight: 400;
    padding: 19px 13px 18px 13px;
    min-height: 60px;
    float: left;
}
.page-header.navbar .hor-menu .navbar-nav>li>a:focus {
    background: 0 0 !important
}
.page-header.navbar .hor-menu .navbar-nav>li.active .selected,
.page-header.navbar .hor-menu .navbar-nav>li.current .selected {
    left: 50%;
    bottom: 0;
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid transparent;
    display: inline-block;
    margin: 0 0 -6px -7px;
    width: 0;
    height: 0
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu {
    margin-top: 0;
    border: none
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a {
    font-family: Poppins,sans-serif;
    font-size: 14px;
    font-weight: 300;
    padding: 9px 10px;
    white-space: normal
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a .badge,
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-menu li>a .label {
    font-weight: 300
}
.page-header.navbar .hor-menu .navbar-nav>li.classic-menu-dropdown .dropdown-menu {
    min-width: 195px;
    max-width: 235px
}
.page-header.navbar .hor-menu .navbar-nav>li.classic-menu-dropdown:hover>.dropdown-menu {
    display: block
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-submenu>.dropdown-menu {
    top: 0
}
.page-header.navbar .hor-menu .navbar-nav>li .dropdown-submenu>a:after {
    top: 9px;
    right: 10px
}
.page-container {
    margin: 0;
    padding: 0;
    position: relative;
    background-color: #222c3c;
}
.page-container:after,
.page-container:before {
    content: " ";
    display: table
}
.page-header-fixed .page-container {
    /*margin-top: 50px;*/
}
.page-full-width.page-header-fixed .page-container {
    margin-top: 110px
}
.page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle {
    padding: 19px 10px 10px;
}
.page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle i {
    top: 0
}
.page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle i:before {
    content: ""
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown-extended .dropdown-menu>li.external h3 {
    text-transform: uppercase;
    padding: 2px 0;
    font-size: 14px;
}
.page-header.navbar .top-menu .navbar-nav>li.dropdown.language-switch>.dropdown-toggle {
    padding: 13px 0px 12px;
    display: block;
}
.fullscreen-btn{
	display: block;
}
.sidemenu-container {
    margin-top: 50px;
}
.sidemenu-container.fixed-menu {
    position: fixed;
    z-index: 999;
}
.sidemenu-closed .sidemenu-container.fixed-menu {
	position: relative;
}
.sidemenu-container-reversed .sidemenu-container.fixed-menu {
    right: 0;
}

/******************************************************************************************

						 		[ 4. SIDE MENU ]

*******************************************************************************************/

.sidemenu-container,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover {} .sidemenu-container .sidemenu>li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    /* border-bottom: 1px solid #1c262f; */
    color: #b7c0cd;
    font-weight: 400 !important;
}
.sidemenu-container .sidemenu>li>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>i {
    color: currentColor
}
.sidemenu-container .sidemenu>li>a>i[class*=icon-],
.sidemenu-container .sidemenu>li>a>i[class^=icon-],
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>i[class*=icon-],
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>i[class^=icon-] {
    color: #6b788b;
}
.sidemenu-container .sidemenu>li>a>.arrow.open:before,
.sidemenu-container .sidemenu>li>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>.arrow:before {
    color: #606C7D;
}
.sidemenu-container .sidemenu>li.heading>h4,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading>h4 {
    color: #708096;
}
.sidemenu-container .sidemenu>li.open>a,
.sidemenu-container .sidemenu>li:hover>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a {
    background-color: #273244;
    color: #fff;
}
.sidemenu-container .sidemenu>li.open>a>.arrow.open:before,
.sidemenu-container .sidemenu>li.open>a>.arrow:before,
.sidemenu-container .sidemenu>li.open>a>i,
.sidemenu-container .sidemenu>li:hover>a>.arrow.open:before,
.sidemenu-container .sidemenu>li:hover>a>.arrow:before,
.sidemenu-container .sidemenu>li:hover>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:hover>a>i {
    color: #fff
}
.sidemenu-container .sidemenu>li.active.open>a,
.sidemenu-container .sidemenu>li.active>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    background-color: #273244;
    border-top-color: transparent;
    color: #fff;
}
.sidemenu-container .sidemenu>li.active.open>a:hover,
.sidemenu-container .sidemenu>li.active>a:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a:hover {
    background-color: #273244;
    color: white;
}
.sidemenu-container .sidemenu>li.active.open>a>.arrow.open:before,
.sidemenu-container .sidemenu>li.active.open>a>.arrow:before,
.sidemenu-container .sidemenu>li.active.open>a>i,
.sidemenu-container .sidemenu>li.active>a>.arrow.open:before,
.sidemenu-container .sidemenu>li.active>a>.arrow:before,
.sidemenu-container .sidemenu>li.active>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>i {
    color: #fff;
}
.sidemenu-container .sidemenu>li.active+li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active+li>a {
    border-top-color: transparent;
}
.sidemenu-container .sidemenu>li.active.open+li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active.open+li>a {
    border-top-color: #3d4957;
}
.sidemenu-container .sidemenu>li:last-child>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li:last-child>a {
    border-bottom: 1px solid transparent!important;
}
.sidemenu-container .sidemenu li:hover>a>.arrow.open:before,
.sidemenu-container .sidemenu li:hover>a>.arrow:before,
.sidemenu-container .sidemenu li>a>.arrow.open:before,
.sidemenu-container .sidemenu li>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li:hover>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li:hover>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a>.arrow:before {
    color: currentColor;
}
.sidemenu-container .sidemenu li.active>a>.arrow.open:before,
.sidemenu-container .sidemenu li.active>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li.active>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li.active>a>.arrow:before {
    color: #fff;
}
/* .sidemenu-closed .sidemenu-container .sidemenu:hover .sub-menu,
.sidemenu-closed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu:hover .sub-menu {
    background-color: #1C262F;
} */
.sidemenu-container .sidemenu .sub-menu>li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a {
    color: #cccbcb;
    font-weight: 400 !important;
}
.sidemenu-container .sidemenu .sub-menu>li>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>i {
    color: #cccbcb;
}
.sidemenu-container .sidemenu .sub-menu>li>a>i[class*=icon-],
.sidemenu-container .sidemenu .sub-menu>li>a>i[class^=icon-],
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>i[class*=icon-],
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>i[class^=icon-] {
    color: #ffffff;
}
.sidemenu-container .sidemenu .sub-menu>li>a>.arrow.open:before,
.sidemenu-container .sidemenu .sub-menu>li>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li>a>.arrow:before {
    color: #ffffff;
}
.sidemenu-container .sidemenu .sub-menu>li.active>a,
.sidemenu-container .sidemenu .sub-menu>li.open>a,
.sidemenu-container .sidemenu .sub-menu>li:hover>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a {
    color: #ffffff;
}
.sidemenu-container .sidemenu .sub-menu>li.active>a>i,
.sidemenu-container .sidemenu .sub-menu>li.open>a>i,
.sidemenu-container .sidemenu .sub-menu>li:hover>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a>i {
    color: #ffffff;
}
.sidemenu-container .sidemenu .sub-menu>li.active>a>.arrow.open:before,
.sidemenu-container .sidemenu .sub-menu>li.active>a>.arrow:before,
.sidemenu-container .sidemenu .sub-menu>li.open>a>.arrow.open:before,
.sidemenu-container .sidemenu .sub-menu>li.open>a>.arrow:before,
.sidemenu-container .sidemenu .sub-menu>li:hover>a>.arrow.open:before,
.sidemenu-container .sidemenu .sub-menu>li:hover>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.active>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li.open>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu>li:hover>a>.arrow:before {
    color: #ffffff;
}
.sidemenu-container .sidebar-search .input-group .input-group-btn .btn>i,
.sidemenu-closed .sidemenu-container .sidebar-search.open .remove>i,
.sidemenu-closed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.open .remove>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .input-group-btn .btn>i {
    color: #B4BCC8;
}
.sidemenu-container .sidebar-toggler,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler {
    background: #2c3541;
}
.sidemenu-container .sidebar-toggler>span,
.sidemenu-container .sidebar-toggler>span:after,
.sidemenu-container .sidebar-toggler>span:before,
.sidemenu-container .sidebar-toggler>span:hover,
.sidemenu-container .sidebar-toggler>span:hover:after,
.sidemenu-container .sidebar-toggler>span:hover:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover:before {
    background: #a7b5c6;
}
.sidemenu-container .sidebar-toggler.th-toggle-exit>span,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler.th-toggle-exit>span {
    background-color: transparent!important;
}
.sidemenu-container .sidebar-toggler:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler:hover {
    background: #212832;
}
.sidemenu-container .sidebar-search .input-group,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group {
    border-bottom: 1px solid #b4bcc8;
}
.sidemenu-container .sidebar-search .input-group .form-control,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .form-control {
    background-color: rgba(255, 255, 255, 0);
    color: #ffffff;
}
.sidemenu-container .sidebar-search .input-group .form-control::-moz-placeholder,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .form-control::-moz-placeholder {
    color: #4e5d6f;
    opacity: 1;
}
.sidemenu-container .sidebar-search .input-group .form-control:-ms-input-placeholder,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .form-control:-ms-input-placeholder {
    color: #4e5d6f;
}
.sidemenu-container .sidebar-search .input-group .form-control::-webkit-input-placeholder,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .form-control::-webkit-input-placeholder {
    color: #4e5d6f;
}
.page-header .search-form-opened .input-group .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #c0c0c0;
  opacity: 1;
}
.page-header .search-form-opened .input-group .form-control::-moz-placeholder { /* Firefox 19+ */
  color: #c0c0c0;
  opacity: 1;
}
.page-header .search-form-opened .input-group .form-control:-ms-input-placeholder { /* IE 10+ */
  color: #c0c0c0;
  opacity: 1;
}
.page-header .search-form-opened .input-group .form-control:-moz-placeholder { /* Firefox 18- */
  color: #c0c0c0;
  opacity: 1;
}
.sidemenu-container .sidebar-search.sidebar-search-bordered .input-group,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-bordered .input-group {
    border: 1px solid #435060;
}
.sidemenu-closed .sidemenu-container .sidebar-search.open .input-group,
.sidemenu-closed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.open .input-group {
    background-color: #1C262F;
}
.sidemenu-closed .sidemenu-container .sidebar-search.sidebar-search-solid .input-group,
.sidemenu-closed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-solid .input-group {
    background: 0 0;
}
.sidemenu-container .sidebar-search.sidebar-search-solid .input-group,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-solid .input-group {
    border: 1px solid #2c3541;
    background: #2c3542;
}
.sidemenu-container .sidebar-search.sidebar-search-solid .input-group .form-control,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-solid .input-group .form-control {
    background: #2c3541;
}
.sidemenu-container .sidebar-search.sidebar-search-solid.open .input-group,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-solid.open .input-group {
    border: 1px solid #1C262F;
    background: #1C262E;
}
.sidemenu-container .sidebar-search.sidebar-search-solid.open .input-group .form-control,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-solid.open .input-group .form-control {
    background: #1C262F;
}
.sidemenu-container .badge {
    font-size: 10px!important
}
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover.navbar-collapse,
.sidemenu-container.navbar-collapse {
    padding: 0;
    box-shadow: none
}
.sidemenu-container .sidemenu,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu {
    list-style: none;
    margin: 0;
    padding: 0
}
.sidemenu-container .sidemenu>li,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li {
    display: block;
    margin: 0;
    border: 0
}
.sidemenu-container .sidemenu>li.sidebar-search-wrapper,
.sidemenu-container .sidemenu>li.sidebar-toggler-wrapper,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-search-wrapper,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-toggler-wrapper {
    border: 0 !important
}
.sidemenu-container .sidemenu>li.sidebar-search-wrapper:after,
.sidemenu-container .sidemenu>li.sidebar-search-wrapper:before,
.sidemenu-container .sidemenu>li.sidebar-toggler-wrapper:after,
.sidemenu-container .sidemenu>li.sidebar-toggler-wrapper:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-search-wrapper:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-search-wrapper:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-toggler-wrapper:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.sidebar-toggler-wrapper:before,
.sidebar-user-panel::before, .sidebar-user-panel::after  {
    content: " ";
    display: table
}
.sidebar-user-panel::after {
    clear: both;
}
.sidemenu-container .sidemenu>li.start>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.start>a {
    border-top-color: transparent !important
}
.sidemenu-container .sidemenu>li.last>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.last>a {
    border-bottom-color: transparent !important
}
.sidemenu-container .sidemenu>li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    display: block;
    position: relative;
    margin: 0;
    padding: 17px 15px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300
}
.sidemenu-container .sidemenu>li>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>i {
    font-size: 18px;
    margin-right: 5px;
    text-shadow: none;
    float: left;
    line-height: 24px;
}
.sidemenu-container .sidemenu.sidemenu-closed>li>a>i {
    float: none;
}
.sidemenu-container .sidemenu>li>a>[class^=icon-],
.sidemenu-container .sidemenu>li>a>i.glyphicon,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>[class^=icon-],
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>i.glyphicon {
    margin-left: 1px;
    margin-right: 4px
}
.sidemenu-container-fixed .sidemenu-container .sidemenu>li>a,
.sidemenu-container-fixed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    transition: all .2s ease
}
.sidemenu-container-reversed.sidemenu-container-fixed .sidemenu-container .sidemenu>li>a,
.sidemenu-container-reversed.sidemenu-container-fixed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a {
    transition: none
}
.sidemenu-container .sidemenu>li.heading,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading {
    padding: 15px
}
.sidemenu-container .sidemenu>li.heading>h3,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading>h3 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 300
}
.sidemenu-container .sidemenu>li.heading+li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.heading+li>a {
    border-top: 0
}
.sidemenu-container .sidemenu>li.open>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.open>a {
    font-size: 14px
}
.sidemenu-container .sidemenu>li.active>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a {
    border: none;
    text-shadow: none;
    font-size: 14px
}
.sidemenu-container .sidemenu>li.active>a>.selected,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.selected {
    display: block;
    float: right;
    position: absolute;
    right: 0;
    top: 8px;
    background: 0 0;
    width: 0;
    height: 0;
}
.sidemenu-container-reversed .sidemenu-container .sidemenu>li.active>a>.selected,
.sidemenu-container-reversed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.selected {
    right: auto;
    left: 0;
    border-right: 0;
    border-left: 8px solid #fff
}
.page-container-bg-solid .sidemenu-container .sidemenu>li.active>a>.selected,
.page-container-bg-solid .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.selected {
    border-color: transparent #eef1f5 transparent transparent
}
.page-container-bg-solid.sidemenu-container-reversed .sidemenu-container .sidemenu>li.active>a>.selected,
.page-container-bg-solid.sidemenu-container-reversed .sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li.active>a>.selected {
    border-color: transparent transparent transparent #eef1f5
}
.sidemenu-container .sidemenu li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a {
    position: relative
}
.sidemenu-container .sidemenu li>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a>.arrow:before {
    float: right;
    width: 20px;
    text-align: center;
    display: inline;
    font-size: 16px;
    font-family: FontAwesome;
    height: auto;
    content: "\f105";
    font-weight: 300;
    text-shadow: none;
    position: absolute;
    top: 4px;
    right: 14px
}
.sidemenu-container .sidemenu li>a>.arrow.open:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a>.arrow.open:before {
    content: "\f107"
}
.sidemenu-container .sidemenu li>a>.badge,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li>a>.badge {
    float: right;
    margin-top: 1px;
    margin-right: 0;
    position: absolute;
    right: 14px;
    top: 6px
}
.sidemenu-container .sidemenu>li>a>.arrow:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu>li>a>.arrow:before {
    top: 14px
}
.sidemenu-container .sidemenu .sub-menu,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu {
    list-style: none;
    display: none;
    padding: 0 0 15px 0;
    margin: 0;
    background-color: #1e293c;
}
.sidemenu-container .sidemenu .sub-menu li,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li {
    background: 0 0;
    margin: 0;
    padding: 0;
}
.sidemenu-container .sidemenu .sub-menu li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>a {
    display: block;
    margin: 0;
    padding: 8px 15px 8px 43px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
    background: 0 0
}
.sidemenu-container .sidemenu .sub-menu li>a>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>a>i {
    font-size: 14px
}
.sidemenu-container .sidemenu .sub-menu li>.sub-menu,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>.sub-menu {
    margin: 0
}
.sidemenu-container .sidemenu .sub-menu li>.sub-menu>li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>.sub-menu>li>a {
    padding-left: 60px
}
.sidemenu-container .sidemenu .sub-menu li>.sub-menu>li>.sub-menu,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>.sub-menu>li>.sub-menu {
    margin: 0
}
.sidemenu-container .sidemenu .sub-menu li>.sub-menu>li>.sub-menu>li>a,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu li>.sub-menu>li>.sub-menu>li>a {
    padding-left: 80px
}
.sidemenu-container .sidemenu .sub-menu.always-open,
.sidemenu-container .sidemenu li.active>.sub-menu,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu .sub-menu.always-open,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidemenu li.active>.sub-menu {
    display: block
}
.sidemenu-container .sidebar-toggler,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler {
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -ms-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    display: block;
    cursor: pointer;
    opacity: .7;
    filter: alpha(opacity=70);
    padding: 6px 8px;
    margin-top: 15px;
    margin-right: 16px;
    float: right;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}
.sidemenu-container .sidebar-toggler>span:hover,
.sidemenu-container .sidebar-toggler>span:hover:after,
.sidemenu-container .sidebar-toggler>span:hover:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:hover:before {
    background: #fff
}
.sidemenu-container .sidebar-toggler>span,
.sidemenu-container .sidebar-toggler>span:after,
.sidemenu-container .sidebar-toggler>span:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:before {
    display: inline-block;
    width: 16px;
    height: 1px;
    background: #fff;
    position: relative;
    top: -5px;
    transition: all ease .3s
}
.sidemenu-container .sidebar-toggler>span:after,
.sidemenu-container .sidebar-toggler>span:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:before {
    position: absolute;
    left: 0;
    content: ''
}
.sidemenu-container .sidebar-toggler>span:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:before {
    top: 5px
}
.sidemenu-container .sidebar-toggler>span:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler>span:after {
    top: -5px
}
.sidemenu-container .sidebar-toggler.th-toggle-exit>span,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler.th-toggle-exit>span {
    background-color: transparent !important
}
.sidemenu-container .sidebar-toggler.th-toggle-exit>span:after,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler.th-toggle-exit>span:after {
    -webkit-transform: translateY(5px) rotateZ(45deg);
    -moz-transform: translateY(5px) rotateZ(45deg);
    -ms-transform: translateY(5px) rotateZ(45deg);
    -o-transform: translateY(5px) rotateZ(45deg);
    transform: translateY(5px) rotateZ(45deg)
}
.sidemenu-container .sidebar-toggler.th-toggle-exit>span:before,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler.th-toggle-exit>span:before {
    -webkit-transform: translateY(-5px) rotateZ(-45deg);
    -moz-transform: translateY(-5px) rotateZ(-45deg);
    -ms-transform: translateY(-5px) rotateZ(-45deg);
    -o-transform: translateY(-5px) rotateZ(-45deg);
    transform: translateY(-5px) rotateZ(-45deg)
}
.sidemenu-container .sidebar-toggler:hover,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-toggler:hover {
    -webkit-transition: opacity .3s;
    -moz-transition: opacity .3s;
    -ms-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    opacity: 1;
    filter: alpha(opacity=100)
}
.sidemenu-container .sidebar-search,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search {
    padding: 0;
    margin: 12px 18px
}
.sidemenu-container .sidebar-search .remove,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .remove {
    display: none
}
.sidemenu-container .sidebar-search .remove>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .remove>i {
    font-size: 16px
}
.sidemenu-container .sidebar-search .input-group,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}
.sidemenu-container .sidebar-search .input-group .form-control,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .form-control {
    border: 0;
    font-size: 14px;
    padding: 0;
    height: auto;
    line-height: normal;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}
.sidemenu-container .sidebar-search .input-group .input-group-btn .btn,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .input-group-btn .btn {
    padding: 2px 0 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 100% 3px
}
.sidemenu-container .sidebar-search .input-group .input-group-btn .btn>i,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search .input-group .input-group-btn .btn>i {
    font-size: 15px
}
.sidemenu-container .sidebar-search.sidebar-search-bordered,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-bordered {
    margin: 25px 18px
}
.sidemenu-container .sidebar-search.sidebar-search-bordered .input-group .form-control,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-bordered .input-group .form-control {
    font-size: 13px;
    padding: 6px 8px
}
.sidemenu-container .sidebar-search.sidebar-search-bordered .input-group .input-group-btn .btn,
.sidemenu-closed.sidemenu-container-fixed .sidemenu-container:hover .sidebar-search.sidebar-search-bordered .input-group .input-group-btn .btn {
    margin-right: 6px
}
.sidemenu-closed.sidemenu-closed-hidelogo .page-header.navbar .menu-toggler.sidebar-toggler {
    margin-right: 13px;
    background: none;
}
.sidebar-container .sidemenu>li.sidebar-mobile-offcanvas-toggler {
    display: none
}
.sidemenu-closed .user-panel {
    display: none;
}
.sidemenu-container{
    background-color: #222c3c;
}
.sidemenu-closed .sidemenu-container .slimScrollDiv {
    overflow: visible!important;
    height: 100%!important;
}
.sidemenu-closed .sidemenu-container .slimscroll-style {
    overflow: visible!important;
    height: 100%!important;
}
/*********************************************************************

					[5. SEARCH BAR ]

**********************************************************************/

.input-group-btn .search-btn {
    padding: 7px 12px 7px;
}
.search-bar-form {
    padding: 0px 0px;
}
.search-form::-webkit-input-placeholder {
    color: #B4BCC8 !important;
}
.search-form:-moz-placeholder {
    color: #B4BCC8 !important;
}
.search-form::-moz-placeholder {
    color: #B4BCC8 !important;
}
.search-form:-ms-input-placeholder {
    color: #B4BCC8 !important;
}
/**************************************************************

					[6. NAVIGATION ]

***************************************************************/

.nav-pills,
.nav-tabs {
    margin-bottom: 10px
}
.nav-pills>li>a,
.nav-tabs>li>a {
    font-size: 14px;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0
}
.nav-pills>li>a>.badge,
.nav-tabs>li>a>.badge {
    margin-top: -6px
}
.nav-pills>li .dropdown-menu:after,
.nav-pills>li .dropdown-menu:before,
.nav-tabs>li .dropdown-menu:after,
.nav-tabs>li .dropdown-menu:before {
    display: none
}
.nav-pills.nav-pills-sm>li>a,
.nav-pills.nav-tabs-sm>li>a,
.nav-tabs.nav-pills-sm>li>a,
.nav-tabs.nav-tabs-sm>li>a {
    font-size: 13px
}
.nav-pills .dropdown.open>.dropdown-toggle,
.nav-tabs .dropdown.open>.dropdown-toggle {
    background: #eee;
    color: #0d638f;
    border-color: transparent
}
.tabs-left.nav-tabs,
.tabs-right.nav-tabs {
    border-bottom: 0
}
.tabs-left.nav-tabs>li,
.tabs-right.nav-tabs>li {
    float: none
}
.tabs-left.nav-tabs>li>a,
.tabs-right.nav-tabs>li>a {
    margin-right: 0;
    margin-bottom: 3px
}
.tabs-left.nav-tabs {
    border-right: 1px solid #ddd;
    display: block;
}
.tabs-left.nav-tabs>li>a {
    display: block;
    margin-right: -1px
}
.tabs-left.nav-tabs>li>a:focus,
.tabs-left.nav-tabs>li>a:hover {
    border-radius: 2px 0 0 2px;
    border-color: #eee #ddd #eee #eee
}
.tabs-left.nav-tabs>li.active>a,
.tabs-left.nav-tabs>li.active>a:hover>li.active>a:focus,
.tabs-left.nav-tabs>li a.active,
.tabs-left.nav-tabs>li a.active:hover, .tabs-left.nav-tabs>li a.active:focus {
    -webkit-border-radius: 2px 0 0 2px;
    -moz-border-radius: 2px 0 0 2px;
    -ms-border-radius: 2px 0 0 2px;
    -o-border-radius: 2px 0 0 2px;
    border-radius: 2px 0 0 2px;
    border-color: #ddd transparent #ddd #ddd
}
.tabs-right.nav-tabs {
    border-left: 1px solid #ddd;
    display: block;
}
.tabs-right.nav-tabs>li>a {
    display: block;
    margin-left: -1px
}
.tabbable:after,
.tabbable:before {
    display: table;
    content: " "
}
.tabs-right.nav-tabs>li>a:focus,
.tabs-right.nav-tabs>li>a:hover {
    border-radius: 0 2px 2px 0;
    border-color: #eee #eee #eee #ddd
}
.tabs-right.nav-tabs>li.active>a,
.tabs-right.nav-tabs>li.active>a:hover>li.active>a:focus,
.tabs-right.nav-tabs>li a.active,
.tabs-right.nav-tabs>li a.active:hover,
.tabs-right.nav-tabs>li a.active:focus {
    -webkit-border-radius: 0 2px 2px 0;
    -moz-border-radius: 0 2px 2px 0;
    -ms-border-radius: 0 2px 2px 0;
    -o-border-radius: 0 2px 2px 0;
    border-radius: 0 2px 2px 0;
    border-color: #ddd #ddd #ddd transparent
}
.tabs-below>.nav-pills,
.tabs-below>.nav-tabs {
    border-bottom: 0;
    margin-bottom: 0;
    margin-top: 10px
}
.tabs-below>.nav-tabs {
    border-top: 1px solid #ddd;
    margin-bottom: 0;
    margin-top: 10px
}
.tabs-below>.nav-tabs>li>a {
    margin-top: -1px;
    margin-bottom: 0
}
.tabs-below>.nav-tabs>li>a:focus,
.tabs-below>.nav-tabs>li>a:hover {
    border-top-color: #ddd;
    border-bottom-color: transparent
}
.tabs-below>.nav-tabs>li>a .dropdown-menu {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px
}
.tabs-below>.nav-tabs .active a,
.tabs-below>.nav-tabs .active a:hover .active a:focus {
    -webkit-border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    -ms-border-radius: 0 0 2px 2px;
    -o-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
    border-color: transparent #ddd #ddd!important
}
.tabbable:after {
    clear: both
}
.tabbable-line>.nav-tabs {
    border: none;
    margin: 0
}
.tabbable-line>.nav-tabs>li {
    margin: 0;
    border-bottom: 4px solid transparent
}
.tabbable-line>.nav-tabs>li>a {
    background: 0 0!important;
    border: 0;
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 18px;
    color: #737373
}
.tabbable-line>.nav-tabs>li>a>i {
    color: #a6a6a6
}
.tabbable-line>.nav-tabs>li.active,
.tabbable-line>.nav-tabs>li a.active,
.borderBox .tabbable-line>.nav-tabs>li a.active {
    background: 0 0;
    border-bottom: 4px solid #36c6d3;
    position: relative
}
.tabbable-line>.nav-tabs>li.active>a {
    border: 0;
    font-size: 18px;
    color: #333
}
.tabbable-line>.nav-tabs>li.active>a>i {
    color: #404040
}
.tabbable-line>.nav-tabs>li.open,
.tabbable-line>.nav-tabs>li:hover {
    background: 0 0;
}
.tabbable-line>.nav-tabs>li.open>a,
.tabbable-line>.nav-tabs>li:hover>a {
    border: 0;
    background: 0 0!important;
    color: #333
}
.tabbable-line>.nav-tabs>li.open>a>i,
.tabbable-line>.nav-tabs>li:hover>a>i {
    color: #a6a6a6
}
.tabbable-line>.tab-content {
    margin-top: 0;
    border: 0;
    border-top: 1px solid #eef1f5;
    padding: 30px 0
}
.page-container-bg-solid .tabbable-line>.tab-content {
    border-top: 1px solid #dae2ea
}
.tabbable-line>.tab-content {
    padding-bottom: 0
}
.tabbable-line.tabs-below>.nav-tabs>li {
    border-top: 4px solid transparent
}
.tabbable-line.tabs-below>.nav-tabs>li:hover {
    border-bottom: 0;
    border-top: 4px solid #fbdcde
}
.tabbable-line.tabs-below>.nav-tabs>li.active {
    margin-bottom: -2px;
    border-bottom: 0;
    border-top: 4px solid #ed6b75
}
.tabbable-line.tabs-below>.tab-content {
    margin-top: -10px;
    border-top: 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px
}
.tabbable-bordered {
    margin-top: 20px
}
.tabbable-bordered .nav-tabs {
    margin-bottom: 0;
    border-bottom: 0
}
.tabbable-bordered .tab-content {
    padding: 30px 20px 20px;
    border: 1px solid #ddd;
    background: #fff
}
/****************************************************************

					[7. PANEL ]

*****************************************************************/

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel-heading-gray {
    background: #eee
}
.panel-heading-purple {
    background: #a860c5;
    color: white;
}
.panel-heading-yellow {
    background: #ECB301;
    color: white;
}
.panel-heading-blue {
    background: #428BCA;
    color: white;
}
.panel-heading-red {
    background: #D9534F;
    color: white;
}
.panel-heading-green {
    background: #8BDB00;
    color: white;
}
.panel-heading a,
.panel-heading a:active,
.panel-heading a:focus,
.panel-heading a:hover {
    text-decoration: none
}
.panel-group .panel {
    overflow: visible
}
.panel .panel-title>a:hover {
    text-decoration: none
}
/********************************************************************

						[8. PAGE CONTENT ]

*********************************************************************/

.page-title {
    padding: 0;
    font-size: 28px;
    letter-spacing: -1px;
    display: block;
    color: #666;
    margin: 0 0 15px;
    font-weight: 300
}
.page-title small {
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 300;
    color: #888
}
.page-container-bg-solid .page-title,
.page-content-white .page-title {
    color: #666;
    margin-bottom: 15px;
    margin-top: 15px
}
.page-container-bg-solid .page-title small,
.page-content-white .page-title small {
    color: #666
}
.page-content-white .page-title {
    font-size: 24px
}
.page-bar {
    padding: 0;
    background-color: #f1f4f7;
    margin-bottom: 25px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}
.page-bar:after,
.page-bar:before {
    content: " ";
    display: table
}
.page-bar .page-breadcrumb {
    display: inline-block;
    float: left;
    padding: 8px;
    margin: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 50px;
    background: rgba(220, 208, 208, 0.3);
    padding-left: 20px !important;
}
.page-bar .page-breadcrumb>li {
    display: inline-block
}
.ie8 .page-bar .page-breadcrumb>li {
    margin-right: 1px
}
.page-bar .page-breadcrumb>li>a,
.page-bar .page-breadcrumb>li>span {
    color: #888;
    font-size: 14px;
    text-shadow: none
}
.page-bar .page-breadcrumb>li>i {
    color: #aaa;
    font-size: 14px;
    text-shadow: none
}
.page-bar .page-breadcrumb>li>i[class*=icon-],
.page-bar .page-breadcrumb>li>i[class^=icon-] {
    color: gray
}
.page-bar .page-toolbar {
    display: inline-block;
    float: right;
    padding: 0
}
.page-title-breadcrumb .breadcrumb li+li:before {
    content: "";
    padding: 0;
}
.page-bar .page-toolbar .btn-fit-height {
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -ms-border-radius: 0 4px 4px 0;
    -o-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    padding-top: 8px;
    padding-bottom: 8px
}
.page-md .page-bar .page-toolbar .btn-fit-height {
    padding-top: 9px;
    padding-bottom: 9px;
    box-shadow: none !important
}
.page-container-bg-solid .page-bar,
.page-content-white .page-bar {
    background-color: #eaeef3;
    position: relative;
    padding: 0 20px;
    margin: 50px -20px 15px;
}
.page-container-bg-solid .page-bar .page-breadcrumb,
.page-content-white .page-bar .page-breadcrumb {
    padding: 15px 15px;
    margin-top: 10px;
}
.page-container-bg-solid .page-bar .page-breadcrumb>li>a,
.page-container-bg-solid .page-bar .page-breadcrumb>li>span,
.page-content-white .page-bar .page-breadcrumb>li>a,
.page-content-white .page-bar .page-breadcrumb>li>span {
    color: #888;
    text-decoration: none;
}
.parent-item:hover {
    color: #1EBDCC !important;
}
.page-container-bg-solid .page-bar .page-breadcrumb>li>i,
.page-content-white .page-bar .page-breadcrumb>li>i {
    color: #aaa
}
.page-container-bg-solid .page-bar .page-breadcrumb>li>i.fa-circle,
.page-content-white .page-bar .page-breadcrumb>li>i.fa-circle {
    font-size: 5px;
    margin: 0 5px;
    position: relative;
    top: -3px;
    opacity: .4;
    filter: alpha(opacity=40)
}
.page-container-bg-solid .page-bar .page-breadcrumb>li>i[class*=icon-],
.page-container-bg-solid .page-bar .page-breadcrumb>li>i[class^=icon-],
.page-content-white .page-bar .page-breadcrumb>li>i[class*=icon-],
.page-content-white .page-bar .page-breadcrumb>li>i[class^=icon-] {
    color: #8c8c8c
}
.page-container-bg-solid .page-bar .page-toolbar,
.page-content-white .page-bar .page-toolbar {
    padding: 6px 0
}
.page-container-bg-solid .page-bar .page-toolbar .btn,
.page-content-white .page-bar .page-toolbar .btn {
    margin-top: -2px
}
.page-container-bg-solid .page-bar .page-toolbar .btn.btn-sm,
.page-content-white .page-bar .page-toolbar .btn.btn-sm {
    margin-top: 0
}
.page-content {
    margin-top: 0;
    padding: 0;
    background-color: #eaeef3;
}
.page-full-width .page-content {
    margin-left: 0 !important
}
/**************************************************************

					[9. FORM ]

***************************************************************/

.form {
    padding: 0!important
}
.form .form-body,
.form-body {
    padding: 20px
}
.form .form-body,
.form-body {
    padding-left: 0;
    padding-right: 0
}
.form .form-actions,
.form-actions {
    padding: 20px;
    margin: 0;
    background-color: #f5f5f5
}
.form .form-actions,
.form-actions {
    background: 0 0;
    padding-left: 0;
    padding-right: 0
}
.form .form-actions.nobg,
.form-actions.nobg,
.tile .tile-object {
    background-color: transparent
}
.form .form-actions.noborder,
.form-actions.noborder {
    border-top: 0
}
.form .form-actions,
.form-actions {
    -webkit-border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    -ms-border-radius: 0 0 2px 2px;
    -o-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px
}
.form .form-actions:after,
.form .form-actions:before,
.form-actions:after,
.form-actions:before {
    content: " ";
    display: table
}
.form .form-actions:after,
.form-actions:after {
    clear: both
}
.form .form-actions.right,
.form-actions.right {
    padding-left: 0;
    padding-right: 20px;
    text-align: right
}
.form .form-actions.right,
.form-actions.right {
    padding-right: 0
}
.form .form-actions.left,
.form-actions.left {
    padding-left: 20px;
    padding-right: 0;
    text-align: left
}
.control-label {
    margin-top: 1px;
    font-weight: 400
}
.control-label .required,
.form-group .required {
    color: #e02222;
    font-size: 12px;
    padding-left: 2px
}
.form-inline input {
    margin-bottom: 0!important
}
.checkbox-list>label.checkbox-inline:first-child,
.checkbox>label,
.form-horizontal .checkbox>label,
.form .form-actions.left,
.form-actions.left,
.radio-list>label.radio-inline:first-child {
    padding-left: 0
}
.form .form-actions.top,
.form-actions.top {
    margin-top: 0;
    margin-bottom: 20px;
    border-top: 0
}
.form .form-actions.top,
.form-actions.top {
    background: 0 0
}
.form .form-actions .btn-set,
.form-actions .btn-set {
    display: inline-block
}
.form .form-section,
.form-section {
    margin: 30px 0;
    padding-bottom: 5px
}
.form .form-bordered .form-group .help-block,
.form .form-row-seperated .form-group .help-block,
.form-horizontal .radio-list>label {
    margin-bottom: 0
}
.form-fit .form .form-section,
.form-fit .form-section {
    margin-left: 20px;
    margin-right: 20px
}
.checkbox,
.form-horizontal .checkbox {
    padding: 0
}
.radio-list>label {
    display: block
}
.form-control-static,
.input-inline,
.radio-list>label.radio-inline {
    display: inline-block
}
.form-horizontal .radio-list .radio {
    padding-top: 1px
}
.form-horizontal .radio>span {
    margin-top: 2px
}
.form .form-row-seperated {
    padding: 0
}
.form .form-row-seperated .form-group {
    margin: 0;
    border-bottom: 1px solid #efefef;
    padding: 15px 0
}
.form .form-row-seperated .form-group.last {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 13px
}
.form .form-row-seperated .form-body {
    padding: 0
}
.form .form-row-seperated .form-actions {
    padding-left: 15px!important;
    padding-right: 15px!important
}
.form .form-bordered .form-group {
    margin: 0;
    border-bottom: 1px solid #efefef
}
.form .form-bordered .form-group>div {
    padding: 15px;
    border-left: 1px solid #efefef
}
.form .form-bordered .form-group.last {
    border-bottom: 0
}
.form .form-bordered .form-group .control-label {
    padding-top: 20px
}
.form .form-bordered .form-group .form-control {
    margin: 0
}
.form .form-bordered .form-body {
    margin: 0;
    padding: 0
}
.form .form-bordered .form-actions {
    margin-top: 0;
    padding-left: 16px!important;
    padding-right: 16px!important
}
.form .form-horizontal.form-bordered.form-row-stripped .form-group:nth-child(even) {
    background-color: #fcfcfc
}
.form .form-horizontal.form-bordered.form-row-stripped .form-control {
    background: #fff!important
}
.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even)>div {
    background-color: #fff
}
.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
    background-color: #fcfcfc
}
.form-horizontal .form-group .input-sm {
    margin-top: 3px
}
.form-horizontal .form-group .form-control-static {
    margin-top: 1px
}
.form-control {
    display: block;
    width: 100%;
    height: 39px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    outline: 0!important;
    box-shadow: none!important;
}
select[multiple], select[size], textarea.form-control {
    height: auto;
}
select.input-sm {
    height: 30px !important;
    line-height: 30px;
}
select.form-control.form-control-sm{
	height: 30px !important;
}
.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}
.input-height {
    height: 45px !important;
}
.form-control.height-auto {
    height: auto
}
.form-horizontal .control-label.small-label {
    float: left;
    text-align: center;
}
textarea.form-control-textarea {
    float: left;
    width: 100%;
}
.input-group-addon>i {
    color: #c5cdda
}
.form-control.white {
    border-color: #fff
}
.form-control.white:focus {
    border-color: #e6e6e6
}
.form-control.focus-white:focus {
    border-color: #fff
}
.form-control.default {
    border-color: #e1e5ec
}
.form-control.default:focus {
    border-color: #c2cad8
}
.form-control.focus-default:focus {
    border-color: #e1e5ec
}
.form-control.dark {
    border-color: #2f353b
}
.form-control.dark:focus {
    border-color: #181c1f
}
.form-control.focus-dark:focus {
    border-color: #2f353b
}
.form-control.blue {
    border-color: #3598dc
}
.form-control.blue:focus {
    border-color: #217ebd
}
.form-control.focus-blue:focus {
    border-color: #3598dc
}
.form-control.green {
    border-color: #32c5d2
}
.form-control.green:focus {
    border-color: #26a1ab
}
.form-control.focus-green:focus {
    border-color: #32c5d2
}
.form-control.grey {
    border-color: #E5E5E5
}
.form-control.grey:focus {
    border-color: #ccc
}
.form-control.focus-grey:focus {
    border-color: #E5E5E5
}
.form-control.red {
    border-color: #e7505a
}
.form-control.red:focus {
    border-color: #e12330
}
.form-control.focus-red:focus {
    border-color: #e7505a
}
.form-control.yellow {
    border-color: #c49f47
}
.form-control.yellow:focus {
    border-color: #a48334
}
.form-control.focus-yellow:focus {
    border-color: #c49f47
}
.form-control.purple {
    border-color: #8E44AD
}
.form-control.purple:focus {
    border-color: #703688
}
.form-control.focus-purple:focus {
    border-color: #8E44AD
}
.form-inline .rt-chkbox {
    margin-left: 15px;
    margin-right: 15px
}
.form-control-static {
    margin: 0
}
.form-group .input-inline {
    margin-right: 5px
}
.patient-img img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.2);
    border: 1px solid #fff;
}
.form-material .form-control, .form-material .form-control.focus, .form-material .form-control:focus {
    background-image: linear-gradient(#9675ce, #9675ce),linear-gradient(rgba(120,130,140,0.13), rgba(120,130,140,0.13));
    border: 0 none;
    border-radius: 0;
    box-shadow: none;
    float: none;
}
.form-material .form-control {
    background-color: rgba(0,0,0,0);
    background-position: center bottom,center calc(99%);
    background-repeat: no-repeat;
    background-size: 0 2px,100% 1px;
    padding: 0;
    transition: background 0s ease-out 0s;
}
.form-material .form-control.focus, .form-material .form-control:focus {
    background-size: 100% 2px,100% 1px;
    outline: 0 none;
    transition-duration: .3s;
}

.fileinput .form-control {
    width: 100%;
    position: relative;
}
.fileinput .form-control {
    padding-top: 7px;
    padding-bottom: 5px;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    cursor: text;
}
.fileinput-new .input-group-addon {
    position: absolute;
    right: 3px;
    top: 3px;
    z-index: 10 !important;
}

/*****************************************************************

					[10. USER PANEL STYLE ]

******************************************************************/

.user-panel {
    float: left;
    width: 100%;
    color: #ccc;
    padding: 10px 10px 20px 10px;
}
.user-online {
    color: #26c281;
}
.user-img-circle {
    background: #fff;
    z-index: 1000;
    position: inherit;
    border: 1px solid rgba(52, 73, 94, 0.44);
    padding: 2px;
}
.txtOnline {
    color: #ccc;
    font-size: 11px;
}
.user-panel .image img {
    max-width: 100%;
}
.user-panel .image {
    width: 35%;
    max-width: 75px;
}
.user-panel .info {
    width: 50%;
    margin-left: 11px;
    margin-top: 6px;
}
.user-panel .info p {
    margin: 5px 0;
}
/*************************************************************

				[11. MODAL WINDOW ]

*************************************************************/

.modal {
    z-index: 10050;
    outline: 0;
    overflow-y: auto!important
}
.modal .modal-header {
    border-bottom: 1px solid #EFEFEF
}
.modal .modal-header h3 {
    font-weight: 300
}
.modal .modal-header .close {
    margin-top: 0!important
}
.modal .modal-dialog {
    z-index: 10051
}
.modal>.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px
}
.modal.in .page-loading {
    display: none
}
.modal-open {
    overflow-y: auto!important
}
.modal-open-noscroll {
    overflow-y: hidden!important
}
.modal-backdrop {
    border: 0;
    outline: 0
}
.modal-backdrop,
.modal-backdrop.fade.in {
    background-color: #333!important
}
.modal.draggable-modal .modal-header {
    cursor: move
}
.modal .modal-content {
    box-shadow: 0 8px 18px rgba(0, 0, 0, .18), 0 6px 6px rgba(0, 0, 0, .23)
}
.note .highlight,
.note code,
.note.note-default {
    background-color: #fff
}
body[ng-controller] .modal-backdrop {
    z-index: 10049
}
body[ng-controller] .modal {
    z-index: 10050
}
.modal-full.modal-dialog {
    width: 99%
}
.modal .modal-content {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    border: 0
}
.modal .modal-content .modal-title {
    font-weight: 400
}
.modal .modal-content .modal-footer {
    border: 0
}
.modal-backdrop.in {
    opacity: .2;
    filter: alpha(opacity=20)
}
/****************************************************************************

						[12. CHAT PANEL ]

*****************************************************************************/

.chat {
    margin: 0;
    padding: 0;
    margin-top: -15px;
    margin-right: 10px;
}
.chat li {
    list-style: none;
    padding: 7px 0 5px;
    margin: 7px auto;
    font-size: 14px;
    float: left;
    width: 100%;
}
.chat li img.avatar {
    height: 45px;
    width: 45px;
    float: left;
    margin-right: 10px;
    margin-top: 0px;
    border-radius: 50% !important;
}
.nice-chat li.in .message {
    background: #817CCE;
    color: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}
ul.chat.nice-chat {
    height: 458px;
}
.chat li.in .message {
    text-align: left;
    margin-left: 65px;
}
.chat li .message {
    display: block;
    padding: 5px;
    position: relative;
}
.nice-chat li.in .message .arrow {
    border-right: 8px solid #817CCE;
}
.chat li.in .message .arrow {
    display: block;
    position: absolute;
    top: 15px;
    left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}
.nice-chat li.in .message a.name,
.nice-chat li.in .message .datetime {
    color: #fff;
}
.nice-chat li.in .message a.name {
    font-weight: bold;
}
.chat li .name {
    font-size: 15px;
    font-weight: 400;
}
.nice-chat li.in .message a.name,
.nice-chat li.in .message .datetime {
    color: #fff;
}
.nice-chat li.in .message .datetime {
    opacity: .7;
}
.chat li .datetime {
    color: #adadad;
    font-size: 13px;
    font-weight: 400;
}
.chat li .message .body {
    display: block;
}
.chat li.out img.avatar {
    float: right;
    margin-left: 10px;
    margin-top: 0px;
}
.nice-chat li.out .message {
    background: #EFF0F4;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}
.nice li.out .message {
    border-right: 2px solid #EFF0F4;
    margin-right: 65px;
    text-align: right;
}
.nice li .message {
    display: block;
    padding: 5px;
    position: relative;
}
.nice-chat li.out .message .arrow {
    border-left: 8px solid #EFF0F4;
}
.chat li.out .message .arrow {
    display: block;
    position: absolute;
    top: 15px;
    right: -8px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #EFF0F4;
}
.chat li.out img.avatar {
    float: right;
    margin-left: 10px;
    margin-top: 0px;
}
.chat li.out .message {
    border-right: 2px solid #EFF0F4;
    margin-right: 65px;
    text-align: right;
}
.chat li .message {
    display: block;
    padding: 5px;
    position: relative;
}
.chat-txt-form {
    margin-top: 15px;
    padding: 10px;
    background-color: #EFF0F4;
    clear: both;
}
.chat-form .form-group {
    width: 83%;
    margin-right: 2%;
    float: left;
}
.chatpane>.toggler,
.chatpane>.toggler-close {
    padding: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    top: 4px;
    cursor: pointer
}
.chatpane {
    width: 100%;
    margin-top: -13px;
    margin-right: 0;
    z-index: 100;
    float: right;
    position: relative
}
.chatpane>.toggler {
    right: 0;
    position: absolute;
    border-radius: 4px
}
.chatpane>.toggler:hover {
    background-color: #3f4f62 !important
}
.chatpane>.toggler-close {
    display: block !important;
    right: 0;
    z-index: 101;
    position: absolute;
    border-radius: 4px
}
.chatpane>.theme-options {
    top: 4px;
    right: 0;
    display: none;
    display: block !important;
    z-index: 100;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px
}
.chatpane>.theme-options>.theme-option {
    color: #c6cfda;
    padding: 15px;
    margin-top: 0;
    margin-bottom: 0
}
.chatpane>.theme-options>.theme-option>span {
    text-transform: uppercase;
    display: inline-block;
    width: 145px;
    font-size: 12px;
    font-weight: 500;
    font-family: Poppins,sans-serif;
}
.chatpane>.theme-options>.theme-option>select.form-control {
    display: inline;
    width: 135px;
    padding: 2px;
    text-transform: lowercase
}
.chatpane>.theme-options>.theme-option.theme-colors {
    border-top: 0
}
.chatpane>.theme-options>.theme-option.theme-colors>span {
    display: block;
    width: auto
}
.chatpane>.theme-options>.theme-option.theme-colors>ul {
    list-style: none;
    padding: 0;
    display: block;
    margin-bottom: 10px !important;
    margin-top: 15px
}
.chatpane>.theme-options>.theme-option.theme-colors>ul>li {
    width: 40px;
    height: 40px;
    margin: 0 4px;
    cursor: pointer;
    list-style: none;
    float: left;
    border: 1px solid #707070
}
.chatpane>.theme-options>.theme-option.theme-colors>ul>li:first-child {
    margin-left: 0
}
.chatpane>.theme-options>.theme-option.theme-colors>ul>li.current,
.chatpane>.theme-options>.theme-option.theme-colors>ul>li:hover {
    border: 2px solid #d64635
}
.chatpane>.theme-options>.theme-option.theme-colors>ul>li.color-default {
    background: #333438
}
.page-container-bg-solid .chatpane,
.page-content-white .chatpane {
    position: absolute;
    margin-top: 30px;
    margin-right: 20px;
    right: 0
}
.page-container-bg-solid.sidemenu-container-reversed.sidemenu-closed .chatpane,
.page-content-white.sidemenu-container-reversed.sidemenu-closed .chatpane {
    margin-right: 65px
}
.chat-sidebar-open .page-header .top-menu .dropdown-quick-sidebar-toggler>.dropdown-toggle i:before,
.chat-sidebar-open .chat-sidebar-toggler>i:before {
    content: ""
}
.chat-sidebar-toggler {
    overflow: hidden;
    z-index: 99999;
    display: none;
    width: 28px;
    height: 27px;
    position: fixed;
    top: 10px;
    right: 15px;
    text-align: center;
    padding-top: 6px
}
.chat-sidebar-toggler:hover {
    background: #303a43
}
.chat-sidebar-open .chat-sidebar-toggler {
    display: inline-block
}
.chat-sidebar-open .chat-sidebar-toggler:hover {
    background: 0 0
}
.chat-sidebar-toggler>i {
    color: #99a8b5;
    font-size: 17px
}
.chat-sidebar-toggler>i:hover {
    color: #fff !important
}
.chat-sidebar-container {
    transition: right .3s;
    z-index: 99999;
    position: fixed;
    top: 60px;
    bottom: 0;
    width: 320px;
    right: -320px;
    overflow: hidden;
    color: #7d7b7b;
    background: #ffffff;
}
.chat-sidebar-open .chat-sidebar-container {
    transition: right .3s;
    right: 0
}
.chat-sidebar-container .chat-sidebar {
    background: #ffffff;
}
.chat-sidebar-container .chat-sidebar .nav-tabs {
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li {
    display: table-cell !important;
    width: 1% !important;
    padding: 0;
    margin: 0;
    float: none
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li>a {
    position: relative;
    display: block;
    text-align: center;
    height: auto;
    font-size: 14px;
    padding: 10px 15px 8px;
    text-transform: uppercase;
    background: 0 0;
    margin-right: 0;
    color: #90a1af;
    border: 0;
    border-bottom: 3px solid rgba(21, 90, 214, 0.3);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    outline: 0 !important
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li>a .material-icons{
    vertical-align: middle;
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li>a>.badge {
    position: absolute;
    top: 10px;
    right: 22px;
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li.active>a,
.chat-sidebar-container .chat-sidebar .nav-tabs>li>a.active,
.chat-sidebar-container .chat-sidebar .nav-tabs>li:hover>a {
    border: 0;
    border-bottom: 3px solid #060bbb;
    background: 0 0;
    color: #7d7b7b;
    text-decoration: none
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu {
    border: 0;
    background: #36424c;
    box-shadow: 5px 5px rgba(97, 117, 135, .1);
    margin-top: 8px;
    margin-right: 20px
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu:before {
    position: absolute;
    top: -7px;
    right: 19px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #36424c;
    content: ''
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu:after {
    position: absolute;
    top: -6px;
    right: 20px;
    display: inline-block !important;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-bottom: 7px solid #36424c;
    content: ''
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li>a {
    padding: 10px 15px;
    color: #99a8b5
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li>a>i {
    color: #93a3b1
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li:hover>a {
    background: #3d4a55;
    color: #99a8b5
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li:hover>a>i {
    color: #9babb8
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li.active>a {
    background: #38444f;
    color: #99a8b5
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li .dropdown-menu>li.divider {
    background-color: #3d4a55
}
.chat-sidebar-container .chat-sidebar .nav-tabs>li.open>a.dropdown-toggle {
    border-bottom: 3px solid #f3565d;
    background: 0 0;
    text-decoration: none;
    color: #90a1af
}
.chat-sidebar-container .chat-sidebar .tab-content {
    margin: 0;
    padding: 0
}
.chat-header {
    background: #ddd;
    padding-top: 10px;
}
.chat-sidebar-container .chat-sidebar .list-heading {
    font-size: 14px;
    margin: 0px 10px 10px 10px;
    font-family: Poppins,sans-serif;
    font-weight: 600;
    color: #00000099;
    background: transparent;
    text-align: center;
    line-height: 15px;
    padding-bottom: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid #80808040;
}
.chat-sidebar-container .chat-sidebar .list-items {
    margin: 0;
    padding: 0;
    list-style: none
}
.chat-sidebar-container .chat-sidebar .list-items>li {
    margin: 0;
    padding: 15px;
    background: #fff;
    color: #585859;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #ddd
}
.chat-sidebar-container .chat-sidebar .list-items>li:hover {
    background: #ddd
}
.chat-sidebar-container .chat-sidebar .list-items>li:last-child {
    border-bottom: 0
}
.chat-sidebar-container .chat-sidebar .list-items.borderless li {
    border: 0;
    text-transform: uppercase;
    font-family: Poppins,sans-serif;
    font-size: 12px;
    font-weight: 500;
}
.chat-sidebar-container .chat-sidebar .inner-content {
    margin: 0px
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-list {
    position: absolute !important;
    width: 320px !important;
    transition: margin .3s
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-item {
    position: absolute !important;
    width: 320px !important;
    transition: margin .3s;
    margin-left: 320px
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-item .page-quick-sidemenu {
    padding: 15px 10px 0
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-item .page-quick-sidemenu .chat-sidebar-back-to-list {
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
    color: #90a1af
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-item .page-quick-sidemenu .chat-sidebar-back-to-list:hover {
    text-decoration: none
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-item .page-quick-sidemenu .chat-sidebar-back-to-list>i {
    font-size: 17px;
    line-height: 17px;
    vertical-align: top;
    margin-right: 3px
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-content-item-shown .chat-sidebar-list {
    transition: margin .3s;
    margin-left: -320px
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-content-item-shown .chat-sidebar-item {
    transition: margin .3s;
    margin-left: 0
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users {
    padding: 0;
    position: relative;
    border-left: 1px solid #e9e9e9;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media {
    padding: 5px 15px 5px 15px;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-object {
    border-radius: 50% !important;
    width: 35px;
    height: 35px;
    filter: alpha(opacity=80);
    float: left;
    margin-right: 10px
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-object:after,
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-object:before {
    content: " ";
    display: table
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-object:after {
    clear: both
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media:hover {
    cursor: pointer
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media:hover .media-object {
    opacity: 1;
    filter: alpha(opacity=100)
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-body .media-heading {
     margin: 5px 0px 3px 0px;
    font-size: 14px;
    line-height: 10px;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-body .media-heading-sub {
    font-size: 10px;
    text-transform: uppercase;
    color: #777
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-body .media-heading-small {
    font-size: 10px;
    color: #5d7081
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .media-status {
    margin-top: 10px;
    right: 10px;
    position: absolute;
    display: inline-block
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages {
    padding: 0 10px;
    border-left: 1px solid #e9e9e9;
    position: relative
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post {
    transition: display .3s;
    padding: 5px 0;
    margin: 10px auto;
    font-size: 13px
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .body {
    color: #333;
    display: block
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .body-out {
    color: #fff;
    display: block
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .avatar {
    width: 45.71px;
    border-radius: 50% !important
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.in .avatar {
    float: left;
    margin-right: 10px
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .avatar {
    float: right;
    margin-left: 10px
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .datetime,
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .name {
    font-size: 12px;
    font-weight: 300;
    color: #3C8DBC;
    font-family: Poppins,sans-serif;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post .message {
    display: block;
    padding: 5px;
    position: relative;
    color: #90a1af;
    background: #36424c;
    border-radius: 5px;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.in .message {
    text-align: left;
    margin-left: 55px;
    background: #eff0f4;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.in .message .arrow {
    display: block;
    position: absolute;
    top: 9px;
    left: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right-width: 6px;
    border-right-style: solid;
    border-right-color: #eff0f4
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.in .msg-in {
    text-align: left;
    margin-left: 55px
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.in .msg-in .arrow {
    display: block;
    position: absolute;
    top: 9px;
    left: -6px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right-width: 6px;
    border-right-style: solid;
    border-right-color: #36424c
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .message {
    margin-right: 55px;
    text-align: right;
    background: #817cce;
}
.chat-sidebar-chat-user {
    background: #fff;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .message .arrow {
    display: block;
    position: absolute;
    top: 9px;
    right: -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left-width: 6px;
    border-left-style: solid;
    border-left-color: #817cce
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .msg-out {
    margin-right: 55px;
    text-align: right
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .msg-out .arrow {
    display: block;
    position: absolute;
    top: 9px;
    right: -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left-width: 6px;
    border-left-style: solid;
    border-left-color: #36424c
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .datetime,
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-messages .post.out .name {
    text-align: right;
    color: white;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-user .chat-sidebar-chat-user-form {
    padding: 20px 10px 15px
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-alerts .chat-sidebar-alerts-list {
    padding: 10px 0;
    position: relative
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-settings .chat-sidebar-settings-list {
    padding: 0;
    position: relative;
    background: #ffffff;
    border-left: 1px solid #e9e9e9;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-settings .chat-sidebar-settings-list .list-items li .bootstrap-switch {
    margin-top: -3px;
    float: right;
    border: 0;
    min-width: 59px;
    border: 1px solid #ddd;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-settings .chat-sidebar-settings-list .list-items li .form-control {
    width: 75px !important;
    padding: 4px !important;
    float: right;
    border: 0;
    margin-top: -4px;
    border: 1px solid #ddd;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-settings .chat-sidebar-settings-list .list-items li select.form-control {
    padding: 4px 0 !important;
    border: 1px solid #ddd;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .dot {
    width: 12px;
    height: 12px;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    left: 37px;
    border: 2px solid #fff;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .online {
    background: #5cc691;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .offline {
    background: #bfbfbf;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .away {
    background: #ffd200;
}
.chat-sidebar-container .chat-sidebar .chat-sidebar-chat .chat-sidebar-chat-users .media-list .media .busy {
    background: #ff6a6a;
}
.chat-txt-form .form-group {
    float: left;
    width: 83%;
}
.chat-sidebar-container .chat-sidebar {
    border: 1px solid #80808036;
}
.chat-box-submit {
    width: 100%;
}
.post {
    padding: 50px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.post.single {
    border-bottom: none;
}
.post:first-child {
    padding-top: 0;
}
.post .entry-title {
    font-size: 30px;
    font-size: 2.1428571429em;
    font-weight: 300;
    color: white;
}
.post .featured-image {
    margin-bottom: 20px;
}
.post .featured-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    display: block;
}
.post p {
    margin-bottom: 30px;
}
.notification-icon {
    width: 36px;
    height: 36px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    padding: 9px 0;
    margin-right: 5px;
}
.settings-list .setting-item {
    border-bottom: 1px solid #eee;
    padding: 15px;
    overflow: hidden;
}
.settings-list .setting-item .setting-text {
    float: left;
    font-size: 13px;
}
.settings-list .setting-item .setting-set {
    float: right;
}
/******************************************************************

					[13. CARD BOX STYLE ]

*********************************************************************/

.card {
    position: relative;
    margin-bottom: 24px;
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.card-topline-yellow {
    border-top: 3px solid #F39C12;
}
.card-topline-aqua {
    border-top: 3px solid #00C0EF;
}
.card-topline-lightblue {
    border-top: 3px solid #3C8DBC;
}
.card-topline-green {
    border-top: 3px solid #00A65A;
}
.card-topline-red {
    border-top: 3px solid #DD4B39;
}
.card-topline-purple {
    border-top: 3px solid #ad5bff;
}
.card-topline-gray {
    border-top: 3px solid #ddd;
}
.card:before,
.card:after {
    content: " ";
    display: table;
}
.card:after {
    clear: both;
}
.card > .nano:last-child {
    border-radius: 0 0 2px 2px;
}
.card.no-shadow {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.card-head {
    border-radius: 2px 2px 0 0;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
    padding: 2px;
    /* text-transform: uppercase; */
    color: #3a405b;
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    min-height: 40px;
}
.card-head:before,
.card-head:after {
    content: " ";
    display: table;
}
.card-head:after {
    clear: both;
}
.card-head header {
    display: inline-block;
    padding: 11px 20px;
    vertical-align: middle;
    line-height: 17px;
    font-size: 20px;
}
.card-head header > h1,
.card-head header > h2,
.card-head header > h3,
.card-head header > h4,
.card-head header > h5,
.card-head header > h6 {
    vertical-align: middle;
    margin: 0;
    line-height: 1;
}
.card-head header small {
    color: inherit;
    opacity: 0.6;
    font-size: 75%;
}
.card-head > div {
    display: inline-block;
}
.card-head .tools {
    padding-right: 16px;
    float: right;
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: 24px;
    line-height: normal;
    vertical-align: middle;
}
.card-head .tools .btn {
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid transparent;
    padding: 0px 2px;
    font-size: 12px;
    background: transparent;
}
.card-head .tools .btn-color {
    color: #97a0b3;
    margin-right: 3px;
    font-size: 12px;
}
.card-head .tools .btn-color:hover {
    color: black;
}
.card-head.card-head-xs header {
    font-size: 15px;
}
.card-head.card-head-sm header {
    font-size: 15px;
}
.card-head.card-head-lg header {
    font-size: 24px;
}
.card-body {
    padding: 10px 24px 14px 24px;
    position: relative;
}
.card-body:before,
.card-body:after {
    content: " ";
    display: table;
}
.card-body:after {
    clear: both;
}
.card-body:last-child {
    border-radius: 0 0 2px 2px;
}
.card-body.table-responsive {
    margin: 0;
}
.card-actionbar {
    padding-bottom: 8px;
    position: relative;
}
.card-actionbar:before,
.card-actionbar:after {
    content: " ";
    display: table;
}
.card-actionbar:after {
    clear: both;
}
.card-actionbar:last-child {
    border-radius: 0 0 2px 2px;
}
.card-actionbar-row {
    padding: 6px 16px;
    text-align: right;
}
.card-tiles > .row,
.card-type-blog-masonry > .row {
    margin: 0;
}
.card-tiles > .row > [class^="col-"],
.card-type-blog-masonry > .row > [class^="col-"] {
    padding: 0;
}
.card-underline .card-head {
    border-bottom: 1px solid rgba(150, 156, 156, 0.4);
}
.card-underline .card-foot {
    border-top: 1px solid rgba(150, 156, 156, 0.4);
}
.card-bordered,
.card-outlined {
    border: 2px solid #969c9c;
}
.cardbox:hover {
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    transition: all 150ms linear;
}
.cardbox {
    background: #fff;
    min-height: 50px;
    position: relative;
    margin-bottom: 20px;
    transition: .5s;
    border: 1px solid #f2f2f2;
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.cardbox .header {
    color: #555;
    padding: 0 0 10px 0;
    position: relative;
    border-bottom: 1px solid rgba(204, 204, 204, 0.8);
    box-shadow: none;
    margin-bottom: 0;
    margin: 15px;
    border-radius: 4px;
    padding: 15px 0;
    background-color: #fff;
}
.cardbox .header h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #3a405b;
    text-transform: uppercase;
}
.cardbox .body {
    font-size: 15px;
    padding: 15px;
    font-weight: 400;
}
.card-box {
    background: #fff;
    min-height: 50px;
    box-shadow: none;
    position: relative;
    margin-bottom: 20px;
    transition: .5s;
    border: 1px solid #f2f2f2;
    border-radius: 0;
}
.card-box:hover {
 /*    box-shadow: 0 1px 5px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    transition: all 150ms linear; */
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    box-shadow: 0 20px 20px rgba(0,0,0,.1);
    -moz-box-shadow: 0 20px 20px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 20px 20px rgba(0,0,0,.1);
}
.course-box {
    padding-left: 20px;
    padding-bottom: 10px;
}
.refresh-block {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .8);
    -webkit-transition: all .05s ease;
    transition: all .05s ease;
    top: 0px;
    left: 0px;
    position: absolute;
    z-index: 1000;
    border-radius: 2px;
}
.refresh-block .refresh-loader {
    display: inline-block;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-left: -16px;
    margin-top: -16px;
}
.refresh-block .refresh-loader i {
    display: inline-block;
    line-height: 32px;
    color: #000;
    font-size: 16px;
}
/*************************************************************************

						[14. PROFILE ]

**************************************************************************/

.doctor-pic {
    padding: 3px;
    border: 3px solid rgb(210, 214, 222);
    border-radius: 50% 50% 50% 50%;
    max-width: 112px;
}
.doctor-profile {
    float: left;
    width: 100%;
    text-align: center;
}
.doctor-name {
    padding: 3px;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
}
#biography {
    padding: 0 20px;
}
.center {
    text-align: center;
}
.white-box {
    background: #ffffff;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 15px;
}
.patient-profile {
    height: 100%;
    width: 100%;
}
.profile-sidebar {
    margin: 20px auto;
}
.profile-content {
    overflow: hidden;
}
.profile-userpic img {
    margin: 0px auto;
    width: 130px;
    padding: 3px;
    border: 3px solid rgb(210, 214, 222);
    border-radius: 50% 50% 50% 50%;
}
.profile-usertitle {
    text-align: center;
    margin-top: 5px;
}
.profile-usertitle-name {
    font-size: 20px;
    margin-bottom: 2px;
    font-weight: bold;
    color: #3a405b;
}
.profile-usertitle-job {
    color: #777777;
    font-size: 12px;
    margin-bottom: 5px;
}
.profile-userbuttons {
    text-align: center;
    margin-top: 10px;
}
.profile-userbuttons .btn {
    margin-right: 5px;
}
.profile-userbuttons .btn:last-child {
    margin-right: 0;
}
.profile-userbuttons button {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
}
.profile-usermenu {
    margin-top: 30px;
    padding-bottom: 20px;
}
.profile-usermenu ul li {
    border-bottom: 1px solid #f0f4f7;
}
.profile-usermenu ul li:last-child {
    border-bottom: none;
}
.profile-usermenu ul li a {
    color: #93a3b5;
    font-size: 16px;
    font-weight: 400;
}
.profile-usermenu ul li a i {
    margin-right: 8px;
    font-size: 16px;
}
.profile-usermenu ul li a:hover {
    background-color: #fafcfd;
    color: #5b9bd1;
}
.profile-usermenu ul li.active a {
    color: #5b9bd1;
    background-color: #f6f9fb;
    border-left: 2px solid #5b9bd1;
    margin-left: -2px;
}
.profile-stat {
    padding-bottom: 20px;
    border-bottom: 1px solid #f0f4f7;
}
.profile-stat-title {
    color: #7f90a4;
    font-size: 25px;
    text-align: center;
}
.profile-stat-text {
    color: #5b9bd1;
    font-size: 11px;
    font-weight: 800;
    text-align: center;
}
.profile-desc-title {
    color: #7f90a4;
    font-size: 17px;
    font-weight: 600;
}
.profile-desc-text {
    color: #7e8c9e;
    font-size: 14px;
}
.profile-desc-link i {
    width: 22px;
    font-size: 19px;
    color: #abb6c4;
    margin-right: 5px;
}
.profile-desc-link a {
    font-size: 14px;
    font-weight: 600;
    color: #5b9bd1;
}
.profile-tabname {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: normal;
}
.thumb-lg {
    width: 33%;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}
.blogThumb {
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.428571429;
    background-color: #fff;
    border: 1px solid #ddd;
}
.thumb-center{
	text-align: center;
}
.thumb-center img{
	display: inline-block;
}
.border-gray {
    border: 1px solid #ddd;
}
.list-group-unbordered > .list-group-item {
    border-left: 0px none;
    border-right: 0px none;
    border-radius: 0px 0px 0px 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.list-group .list-group-item {
    display: block;
}
.profile-desc {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 25px;
}
.profile-desc-item {
    text-align: left;
    font-size: 14px;
    color: #6f6d6d;
    width: 100px;
}
.performance-list {
    list-style: none outside none;
    padding: 0px;
}
.performance-list li a {
    color: rgb(158, 158, 158);
    margin-bottom: 10px;
    display: block;
    text-decoration: none;
}
.performance-list li a:hover {
    color: #00C0EF;
}
.work-monitor .title {
    margin: 0 0 40px 0;
}
.work-monitor .title h3 {
    font-size: 14px;
    text-transform: uppercase;
    color: #555;
    margin: 20px 0 10px 0;
}
.work-monitor .title p {
    font-size: 14px;
    color: #b1b5b7;
}
.work-monitor .states {
    width: 90%;
}
.work-monitor .states .info {
    display: inline-block;
    width: 100%;
    font-size: 13px;
}
.work-progress .states {
    width: 100%;
}
.p-text-area,
.p-text-area:focus {
    box-shadow: none;
    color: #C3C3C3;
    font-size: 16px;
    font-weight: 300;
    border: 2px solid #f5f5f5;
}
ul.activity-list {
    list-style-type: none;
    padding: 0;
}
ul.activity-list li {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eff0f4;
}
ul.activity-list .avatar img {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}
ul.activity-list .activity-desk {
    margin-left: 70px;
}
ul.activity-list .activity-desk h5 {
    color: #2a323f;
}
ul.activity-list .activity-desk h5 a {
    font-weight: bold;
}
.activity-desk .album a {
    float: left;
    margin-right: 10px;
    width: 170px;
    height: 110px;
    overflow: hidden;
    margin-bottom: 10px;
}
.activity-desk .album a img {
    width: 100%;
}
#map-canvas {
    height: 200px;
    width: 100%;
}
.revenue-graph {
    height: 220px;
}
/**********************************************************

						[15. WIZARD ]

***********************************************************/

.sw-toolbar-top,
.sw-toolbar-bottom{
	flex-direction: row-reverse;
	padding-top: 15px;
}
.sw-toolbar-top .navbar-btn .btn{
	margin-left: 10px;
}
.sw-toolbar-bottom .navbar-btn .btn{
	margin-left: 10px;
}
.wizard-form .navbar-btn .btn{
	margin-left: 10px;
}
.wizard-form .form-group{
	padding-right: 20px;
}

.checkbox-list>label {
    display: block
}
.checkbox-list>label.checkbox-inline {
    display: inline-block
}

.sw-main .nav-tabs>li {
    width: 25%;
}
.sw-main.sw-theme-circles .nav-tabs>li {
    width: calc(25% - 40px);
}
/****************************************************************

					[16. TABLE ]

*****************************************************************/

.table-scrollable {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid #e7ecf1;
    margin: 10px 0!important
}
.DTS .table-scrollable,
.table-scrollable.table-scrollable-borderless,
.table-scrollable>.table-bordered {
    border: 0
}
.table-scrollable>.table-bordered>tbody>tr:last-child>td,
.table-scrollable>.table-bordered>tbody>tr:last-child>th,
.table-scrollable>.table-bordered>tfoot>tr:last-child>td,
.table-scrollable>.table-bordered>tfoot>tr:last-child>th,
.table-scrollable>.table-bordered>thead>tr:last-child>td,
.table-scrollable>.table-bordered>thead>tr:last-child>th,
.table.table-bordered thead>tr>th {
    border-bottom: 0
}
.table-scrollable>.table {
    width: 100%!important;
    margin: 0!important;
    background-color: #fff
}
.table-scrollable>.table>tbody>tr>th,
.table-scrollable>.table>tfoot>tr>td,
.table-scrollable>.table>tfoot>tr>th,
.table-scrollable>.table>thead>tr>th {
    white-space: nowrap
}
.table-scrollable>.table-bordered>tbody>tr>td:first-child,
.table-scrollable>.table-bordered>tbody>tr>th:first-child,
.table-scrollable>.table-bordered>tfoot>tr>td:first-child,
.table-scrollable>.table-bordered>tfoot>tr>th:first-child,
.table-scrollable>.table-bordered>thead>tr>td:first-child,
.table-scrollable>.table-bordered>thead>tr>th:first-child {
    border-left: 0
}
.table-scrollable>.table-bordered>tbody>tr>td:last-child,
.table-scrollable>.table-bordered>tbody>tr>th:last-child,
.table-scrollable>.table-bordered>tfoot>tr>td:last-child,
.table-scrollable>.table-bordered>tfoot>tr>th:last-child,
.table-scrollable>.table-bordered>thead>tr>td:last-child,
.table-scrollable>.table-bordered>thead>tr>th:last-child {
    border-right: 0
}
.table td,
.table th,
.card .table td,
.card .table th,
.card .dataTable td,
.card .dataTable th {
    padding: 15px 8px;
    vertical-align: middle;
}
.table td.valigntop {
    vertical-align: top;
}
.table-toolbar {
    margin-bottom: 20px
}
.table-toolbar:after,
.table-toolbar:before {
    content: " ";
    display: table
}
.table-toolbar:after {
    clear: both
}
.table.table-full-width {
    width: 100%!important
}
.table .btn {
    margin-left: 0;
    margin-right: 5px
}
.table thead tr th {
    font-size: 14px;
    font-weight: 600
}
.table-advance {
    margin-bottom: 10px!important
}
.table-advance thead {
    color: #3f444a
}
.table-advance thead tr th {
    background-color: #f1f4f7;
    font-size: 14px;
    font-weight: 400;
    color: #3f444a
}
.table-advance div.danger,
.table-advance div.important,
.table-advance div.info,
.table-advance div.success,
.table-advance div.warning {
    position: absolute;
    margin-top: -5px;
    float: left;
    width: 2px;
    height: 30px;
    margin-right: 20px!important
}
.table-advance tr td {
    border-left-width: 0
}
.table-advance tr td:first-child {
    border-left-width: 2px!important
}
.table-advance tr td.highlight:first-child a {
    margin-left: 15px
}
.table-advance td.highlight div.primary {
    border-left: 2px solid #337ab7
}
.table-advance td.highlight div.success {
    border-left: 2px solid #36c6d3
}
.table-advance td.highlight div.info {
    border-left: 2px solid #659be0
}
.table-advance td.highlight div.warning {
    border-left: 2px solid #F1C40F
}
.table-advance td.highlight div.danger {
    border-left: 2px solid #ed6b75
}
.table.table-light {
    border: 0!important
}
.table.table-light>thead>tr:hover>th {
    background: 0 0
}
.table.table-light>thead>tr.uppercase {
    text-transform: uppercase
}
.table.table-light>thead>tr>th {
    font-weight: 600;
    font-size: 13px;
    color: #93a2a9;
    font-family: Poppins,sans-serif;
    border: 0;
    border-bottom: 1px solid #F2F5F8
}
.table.table-light>tbody>tr:last-child>td {
    border: 0
}
.table.table-light>tbody>tr>td {
    border: 0;
    border-bottom: 1px solid #F2F5F8;
    color: #8896a0;
    vertical-align: middle
}
.table.table-light>tbody>tr>td.fit {
    width: 1px;
    padding-right: 3px
}
.table.table-light>tbody>tr>td .user-pic {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%
}
.table.table-light.table-hover>tbody>tr:hover>td,
.table.table-light.table-hover>tbody>tr>td:hover,
.table.table-light.table-hover>tbody>tr>th:hover {
    background: #f9fafb!important
}
.table-hover>tbody>tr:hover,
.table-hover>tbody>tr:hover>td {
    background: #f3f4f6!important
}
table.dataTable tbody td.patient-img {
    padding: 2px;
}
table.valign-middle tbody tr td {
    vertical-align: middle;
}
/*********************************************************************

					[17. BUTTON ]

**********************************************************************/

.btn {
    outline: 0!important
}
.btn:hover {
    transition: all .3s
}

.btn-default,
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open > .dropdown-toggle.btn-default,
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .dropdown-toggle.btn-default.focus,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:hover {
  background-color: #e1e5ec !important;
  border-color: #e1e5ec !important;
}
.btn-primary {
  background-color: #188ae2 !important;
  border: 1px solid #188ae2 !important;
  color: #fff !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  background-color: #167ccb !important;
  border: 1px solid #167ccb !important;
  color: #fff !important;
}
.btn-success {
  background-color: #4bd396 !important;
  border: 1px solid #4bd396 !important;
  color: #fff !important;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open > .dropdown-toggle.btn-success {
  background-color: #37ce8a !important;
  border: 1px solid #37ce8a !important;
  color: #fff !important;
}
.btn-info {
  background-color: #3ac9d6 !important;
  border: 1px solid #3ac9d6 !important;
  color: #fff !important;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open > .dropdown-toggle.btn-info {
  background-color: #2abfcc !important;
  border: 1px solid #2abfcc !important;
  color: #fff !important;
}
.btn-warning {
  background-color: #f9c851 !important;
  border: 1px solid #f9c851 !important;
  color: #fff !important;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
  background-color: #f8c038 !important;
  border: 1px solid #f8c038 !important;
  color: #fff !important;
}
.btn-danger {
  background-color: #f5707a !important;
  border: 1px solid #f5707a !important;
  color: #fff !important;
}
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
  background-color: #f35864 !important;
  border: 1px solid #f35864 !important;
  color: #fff !important;
}

.btn-pink {
  background-color: #ff4081 !important;
  border: 1px solid #ff4081 !important;
  color: #fff !important;
}
.btn-pink:active,
.btn-pink:focus,
.btn-pink:hover,
.btn-pink.active,
.btn-pink.focus,
.btn-pink:active,
.btn-pink:focus,
.btn-pink:hover,
.open > .dropdown-toggle.btn-pink {
  background-color: #ff4081 !important;
  border: 1px solid #ff4081 !important;
  color: #fff !important;
}


/* .btn.btn-default {
    color: #666;
    background-color: #e1e5ec;
    border-color: #e1e5ed;
}
.btn.btn-default:hover {
    color: #666;
    background-color: #c2cad8;
    border-color: #bcc5d4;
}
.btn-primary {
    background-color: #4489e4;
    border-color: #4489e4;
    color: #FFFFFF;
}
.btn-info {
    background-color: #34d3eb !important;
    border: 1px solid #34d3eb !important;
}
.btn-warning {
  background-color: #ffa91c !important;
  border: 1px solid #ffa91c !important;
}
.btn-success {
  background-color: #32c861 !important;
  border: 1px solid #32c861 !important;
}
.btn-danger {
  background-color: #f96a74 !important;
  border: 1px solid #f96a74 !important;
} */
.btn:hover,
.icon-btn:hover,
.btn-group>.btn:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .2), 0 3px 6px rgba(0, 0, 0, .26)
}
.btn:active {
    box-shadow: 0 14px 28px rgba(0, 0, 0, .26), 0 10px 10px rgba(0, 0, 0, .22)
}
.btn:not(.btn-sm):not(.btn-lg) {} .btn.white:not(.btn-outline) {
    color: #666;
    background-color: #fff;
    border-color: #fbfbfb;
}
.btn.white:not(.btn-outline).focus,
.btn.white:not(.btn-outline):focus {
    color: #666;
    background-color: #e6e6e6;
    border-color: #bfbfbf
}
.btn.white:not(.btn-outline).active,
.btn.white:not(.btn-outline):active,
.btn.white:not(.btn-outline):hover,
.open>.btn.white:not(.btn-outline).dropdown-toggle {
    color: #666;
    background-color: #e6e6e6;
    border-color: #e0e0e0
}
.btn.white:not(.btn-outline).active.focus,
.btn.white:not(.btn-outline).active:focus,
.btn.white:not(.btn-outline).active:hover,
.btn.white:not(.btn-outline):active.focus,
.btn.white:not(.btn-outline):active:focus,
.btn.white:not(.btn-outline):active:hover,
.open>.btn.white:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.white:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.white:not(.btn-outline).dropdown-toggle:hover {
    color: #666;
    background-color: #d4d4d4;
    border-color: #bfbfbf
}
.btn.white:not(.btn-outline).disabled.focus,
.btn.white:not(.btn-outline).disabled:focus,
.btn.white:not(.btn-outline).disabled:hover,
.btn.white:not(.btn-outline)[disabled].focus,
.btn.white:not(.btn-outline)[disabled]:focus,
.btn.white:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.white:not(.btn-outline).focus,
fieldset[disabled] .btn.white:not(.btn-outline):focus,
fieldset[disabled] .btn.white:not(.btn-outline):hover {
    background-color: #fff;
    border-color: #fbfbfb;
}
.btn.white:not(.btn-outline) .badge {
    color: #fff;
    background-color: #666
}
.btn.btn-outline.white {
    border-color: #fff;
    color: #fff;
    background: 0 0
}
.btn.btn-outline.white.active,
.btn.btn-outline.white:active,
.btn.btn-outline.white:active:focus,
.btn.btn-outline.white:active:hover,
.btn.btn-outline.white:focus,
.btn.btn-outline.white:hover {
    border-color: #fff;
    color: #666;
    background-color: #fbfbfb
}
.btn.white-stripe {
    border-left: 4px solid #fff!important
}
.btn.white.btn-no-border:not(.active) {
    border-color: transparent
}
.btn.default:not(.btn-outline) {
    color: #666;
    background-color: #e1e5ec;
    border-color: #e1e5ed
}
.btn.default:not(.btn-outline).focus,
.btn.default:not(.btn-outline):focus {
    color: #666;
    background-color: #c2cad8;
    border-color: #93a1bb
}
.btn.default:not(.btn-outline).active,
.btn.default:not(.btn-outline):active,
.btn.default:not(.btn-outline):hover,
.open>.btn.default:not(.btn-outline).dropdown-toggle {
    color: #666;
    background-color: #c2cad8;
    border-color: #bcc5d4
}
.btn.default:not(.btn-outline).active.focus,
.btn.default:not(.btn-outline).active:focus,
.btn.default:not(.btn-outline).active:hover,
.btn.default:not(.btn-outline):active.focus,
.btn.default:not(.btn-outline):active:focus,
.btn.default:not(.btn-outline):active:hover,
.open>.btn.default:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.default:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.default:not(.btn-outline).dropdown-toggle:hover {
    color: #666;
    background-color: #acb7ca;
    border-color: #93a1bb
}
.btn.default:not(.btn-outline).active,
.btn.default:not(.btn-outline):active,
.open>.btn.default:not(.btn-outline).dropdown-toggle {
    background-image: none
}
.btn.default:not(.btn-outline).disabled.focus,
.btn.default:not(.btn-outline).disabled:focus,
.btn.default:not(.btn-outline).disabled:hover,
.btn.default:not(.btn-outline)[disabled].focus,
.btn.default:not(.btn-outline)[disabled]:focus,
.btn.default:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.default:not(.btn-outline).focus,
fieldset[disabled] .btn.default:not(.btn-outline):focus,
fieldset[disabled] .btn.default:not(.btn-outline):hover {
    background-color: #e1e5ec;
    border-color: #e1e5ed;
}
.btn.default:not(.btn-outline) .badge {
    color: #e1e5ec;
    background-color: #666
}
.btn.btn-outline.default {
    border-color: #e1e5ec;
    color: #e1e5ec;
    background: 0 0
}
.btn.btn-outline.default.active,
.btn.btn-outline.default:active,
.btn.btn-outline.default:active:focus,
.btn.btn-outline.default:active:hover,
.btn.btn-outline.default:focus,
.btn.btn-outline.default:hover {
    border-color: #e1e5ec;
    color: #666;
    background-color: #e1e5ed
}
.btn.default-stripe {
    border-left: 4px solid #e1e5ec!important
}
.btn.default.btn-no-border:not(.active) {
    border-color: transparent
}
.btn.dark:not(.btn-outline) {
    color: #FFF;
    background-color: #2f353b;
    border-color: #2f353c
}
.btn.dark:not(.btn-outline).focus,
.btn.dark:not(.btn-outline):focus {
    color: #FFF;
    background-color: #181c1f;
    border-color: #000
}
.btn.dark:not(.btn-outline).active,
.btn.dark:not(.btn-outline):active,
.btn.dark:not(.btn-outline):hover,
.open>.btn.dark:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #181c1f;
    border-color: #141619
}
.btn.dark:not(.btn-outline).active.focus,
.btn.dark:not(.btn-outline).active:focus,
.btn.dark:not(.btn-outline).active:hover,
.btn.dark:not(.btn-outline):active.focus,
.btn.dark:not(.btn-outline):active:focus,
.btn.dark:not(.btn-outline):active:hover,
.open>.btn.dark:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.dark:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.dark:not(.btn-outline).dropdown-toggle:hover {
    color: #FFF;
    background-color: #090a0b;
    border-color: #000
}
.btn.dark:not(.btn-outline).active,
.btn.dark:not(.btn-outline):active,
.open>.btn.dark:not(.btn-outline).dropdown-toggle {
    background-image: none
}
.btn.dark:not(.btn-outline).disabled.focus,
.btn.dark:not(.btn-outline).disabled:focus,
.btn.dark:not(.btn-outline).disabled:hover,
.btn.dark:not(.btn-outline)[disabled].focus,
.btn.dark:not(.btn-outline)[disabled]:focus,
.btn.dark:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.dark:not(.btn-outline).focus,
fieldset[disabled] .btn.dark:not(.btn-outline):focus,
fieldset[disabled] .btn.dark:not(.btn-outline):hover {
    background-color: #2f353b;
    border-color: #2f353c
}
.btn.dark:not(.btn-outline) .badge {
    color: #2f353b;
    background-color: #FFF
}
.btn.btn-outline.dark {
    border-color: #2f353b;
    color: #2f353b;
    background: 0 0
}
.btn.btn-outline.dark.active,
.btn.btn-outline.dark:active,
.btn.btn-outline.dark:active:focus,
.btn.btn-outline.dark:active:hover,
.btn.btn-outline.dark:focus,
.btn.btn-outline.dark:hover {
    border-color: #2f353b;
    color: #FFF;
    background-color: #2f353c
}
.btn.dark-stripe {
    border-left: 4px solid #2f353b!important
}
.btn.dark.btn-no-border:not(.active) {
    border-color: transparent
}
.btn.blue:not(.btn-outline) {
    color: #FFF;
    background-color: #3598dc;
    border-color: #3598dd
}
.btn.blue:not(.btn-outline).focus,
.btn.blue:not(.btn-outline):focus {
    color: #FFF;
    background-color: #217ebd;
    border-color: #15527c
}
.btn.blue:not(.btn-outline).active,
.btn.blue:not(.btn-outline):active,
.btn.blue:not(.btn-outline):hover,
.open>.btn.blue:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #217ebd;
    border-color: #1f78b5
}
.btn.blue:not(.btn-outline).active.focus,
.btn.blue:not(.btn-outline).active:focus,
.btn.blue:not(.btn-outline).active:hover,
.btn.blue:not(.btn-outline):active.focus,
.btn.blue:not(.btn-outline):active:focus,
.btn.blue:not(.btn-outline):active:hover,
.open>.btn.blue:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.blue:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.blue:not(.btn-outline).dropdown-toggle:hover {
    color: #FFF;
    background-color: #1c699f;
    border-color: #15527c
}
.btn.blue:not(.btn-outline).active,
.btn.blue:not(.btn-outline):active,
.open>.btn.blue:not(.btn-outline).dropdown-toggle {
    background-image: none
}
.btn.blue:not(.btn-outline).disabled.focus,
.btn.blue:not(.btn-outline).disabled:focus,
.btn.blue:not(.btn-outline).disabled:hover,
.btn.blue:not(.btn-outline)[disabled].focus,
.btn.blue:not(.btn-outline)[disabled]:focus,
.btn.blue:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.blue:not(.btn-outline).focus,
fieldset[disabled] .btn.blue:not(.btn-outline):focus,
fieldset[disabled] .btn.blue:not(.btn-outline):hover {
    background-color: #3598dc;
    border-color: #3598dd
}
.btn.blue:not(.btn-outline) .badge {
    color: #3598dc;
    background-color: #FFF
}
.btn.btn-outline.blue {
    border-color: #3598dc;
    color: #3598dc;
    background: 0 0
}
.btn.btn-outline.blue.active,
.btn.btn-outline.blue:active,
.btn.btn-outline.blue:active:focus,
.btn.btn-outline.blue:active:hover,
.btn.btn-outline.blue:focus,
.btn.btn-outline.blue:hover {
    border-color: #3598dc;
    color: #FFF;
    background-color: #3598dd
}
.btn.green-bgcolor:not(.btn-outline) {
    color: #FFF;
    background-color: #69C2FE;
    border-color: #69C2FF
}
.btn.green-bgcolor:not(.btn-outline).focus,
.btn.green-bgcolor:not(.btn-outline):focus {
    color: #FFF;
    background-color: #26a1ab;
    border-color: #18666d
}
.btn.green-bgcolor:not(.btn-outline).active,
.btn.green-bgcolor:not(.btn-outline):active,
.btn.green-bgcolor:not(.btn-outline):hover,
.open>.btn.green-bgcolor:not(.btn-outline).dropdown-toggle {
    color: #FFF;
    background-color: #69C2FE;
    border-color: #69C2FF;
}
.btn.green-bgcolor:not(.btn-outline).active.focus,
.btn.green-bgcolor:not(.btn-outline).active:focus,
.btn.green-bgcolor:not(.btn-outline).active:hover,
.btn.green-bgcolor:not(.btn-outline):active.focus,
.btn.green-bgcolor:not(.btn-outline):active:focus,
.btn.green-bgcolor:not(.btn-outline):active:hover,
.open>.btn.green-bgcolor:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.green-bgcolor:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.green-bgcolor:not(.btn-outline).dropdown-toggle:hover {
    color: #FFF;
    background-color: #1f858e;
    border-color: #18666d
}
.btn.green-bgcolor:not(.btn-outline).active,
.btn.green-bgcolor:not(.btn-outline):active,
.open>.btn.green-bgcolor:not(.btn-outline).dropdown-toggle {
    background-image: none
}
.btn.green-bgcolor:not(.btn-outline).disabled.focus,
.btn.green-bgcolor:not(.btn-outline).disabled:focus,
.btn.green-bgcolor:not(.btn-outline).disabled:hover,
.btn.green-bgcolor:not(.btn-outline)[disabled].focus,
.btn.green-bgcolor:not(.btn-outline)[disabled]:focus,
.btn.green-bgcolor:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.green-bgcolor:not(.btn-outline).focus,
fieldset[disabled] .btn.green-bgcolor:not(.btn-outline):focus,
fieldset[disabled] .btn.green-bgcolor:not(.btn-outline):hover {
    background-color: #32c5d2;
    border-color: #32c5d3
}
.btn.green-bgcolor:not(.btn-outline) .badge {
    color: #32c5d2;
    background-color: #FFF
}
.btn.grey:not(.btn-outline) {
    color: #333;
    background-color: #E5E5E5;
    border-color: #E5E5E6
}
.btn.grey:not(.btn-outline).focus,
.btn.grey:not(.btn-outline):focus {
    color: #333;
    background-color: #ccc;
    border-color: #a5a5a5
}
.btn.grey:not(.btn-outline).active,
.btn.grey:not(.btn-outline):active,
.btn.grey:not(.btn-outline):hover,
.open>.btn.grey:not(.btn-outline).dropdown-toggle {
    color: #333;
    background-color: #ccc;
    border-color: #c6c6c6
}
.btn.grey:not(.btn-outline).active.focus,
.btn.grey:not(.btn-outline).active:focus,
.btn.grey:not(.btn-outline).active:hover,
.btn.grey:not(.btn-outline):active.focus,
.btn.grey:not(.btn-outline):active:focus,
.btn.grey:not(.btn-outline):active:hover,
.open>.btn.grey:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.grey:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.grey:not(.btn-outline).dropdown-toggle:hover {
    color: #333;
    background-color: #bababa;
    border-color: #a5a5a5
}
.btn.grey:not(.btn-outline).active,
.btn.grey:not(.btn-outline):active,
.open>.btn.grey:not(.btn-outline).dropdown-toggle {
    background-image: none
}
.btn.grey:not(.btn-outline).disabled.focus,
.btn.grey:not(.btn-outline).disabled:focus,
.btn.grey:not(.btn-outline).disabled:hover,
.btn.grey:not(.btn-outline)[disabled].focus,
.btn.grey:not(.btn-outline)[disabled]:focus,
.btn.grey:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.grey:not(.btn-outline).focus,
fieldset[disabled] .btn.grey:not(.btn-outline):focus,
fieldset[disabled] .btn.grey:not(.btn-outline):hover {
    background-color: #E5E5E5;
    border-color: #E5E5E6
}
.btn.grey:not(.btn-outline) .badge {
    color: #E5E5E5;
    background-color: #333
}
.btn.btn-outline.grey {
    border-color: #E5E5E5;
    color: #E5E5E5;
    background: 0 0
}
.btn.btn-outline.grey.active,
.btn.btn-outline.grey:active,
.btn.btn-outline.grey:active:focus,
.btn.btn-outline.grey:active:hover,
.btn.btn-outline.grey:focus,
.btn.btn-outline.grey:hover {
    border-color: #E5E5E5;
    color: #333;
    background-color: #E5E5E6
}
.btn.red:not(.btn-outline) {
    color: #fff;
    background-color: #e7505a;
    border-color: #e7505b
}
.btn.red:not(.btn-outline).focus,
.btn.red:not(.btn-outline):focus {
    color: #fff;
    background-color: #e12330;
    border-color: #a1161f
}
.btn.red:not(.btn-outline).active,
.btn.red:not(.btn-outline):active,
.btn.red:not(.btn-outline):hover,
.open>.btn.red:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #c71c27;
    border-color: #dc1e2b
}
.btn.red:not(.btn-outline).active.focus,
.btn.red:not(.btn-outline).active:focus,
.btn.red:not(.btn-outline).active:hover,
.btn.red:not(.btn-outline):active.focus,
.btn.red:not(.btn-outline):active:focus,
.btn.red:not(.btn-outline):active:hover,
.open>.btn.red:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.red:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.red:not(.btn-outline).dropdown-toggle:hover {
    color: #fff;
    background-color: #c51b26;
    border-color: #d82431
}
a.btn.blue-bgcolor:hover {
    color: #fff;
}
.btn.red:not(.btn-outline).active,
.btn.red:not(.btn-outline):active,
.open>.btn.red:not(.btn-outline).dropdown-toggle {
    background-image: none
}
.btn.red:not(.btn-outline).disabled.focus,
.btn.red:not(.btn-outline).disabled:focus,
.btn.red:not(.btn-outline).disabled:hover,
.btn.red:not(.btn-outline)[disabled].focus,
.btn.red:not(.btn-outline)[disabled]:focus,
.btn.red:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.red:not(.btn-outline).focus,
fieldset[disabled] .btn.red:not(.btn-outline):focus,
fieldset[disabled] .btn.red:not(.btn-outline):hover {
    background-color: #e7505a;
    border-color: #e7505b
}
.btn.red:not(.btn-outline) .badge {
    color: #e7505a;
    background-color: #fff
}
.btn.btn-outline.red {
    border-color: #e7505a;
    color: #e7505a;
    background: 0 0
}
.btn.btn-outline.red.active,
.btn.btn-outline.red:active,
.btn.btn-outline.red:active:focus,
.btn.btn-outline.red:active:hover,
.btn.btn-outline.red:focus,
.btn.btn-outline.red:hover {
    border-color: #e7505a;
    color: #fff;
    background-color: #e7505b
}
.btn.yellow:not(.btn-outline) {
    color: #fff;
    background-color: #c49f47;
    border-color: #c49f48
}
.btn.yellow:not(.btn-outline).focus,
.btn.yellow:not(.btn-outline):focus {
    color: #fff;
    background-color: #a48334;
    border-color: #6a5422
}
.btn.yellow:not(.btn-outline).active,
.btn.yellow:not(.btn-outline):active,
.btn.yellow:not(.btn-outline):hover,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #a48334;
    border-color: #9c7c32
}
.btn.yellow:not(.btn-outline).active.focus,
.btn.yellow:not(.btn-outline).active:focus,
.btn.yellow:not(.btn-outline).active:hover,
.btn.yellow:not(.btn-outline):active.focus,
.btn.yellow:not(.btn-outline):active:focus,
.btn.yellow:not(.btn-outline):active:hover,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle:hover {
    color: #fff;
    background-color: #896d2c;
    border-color: #6a5422
}
.btn.yellow:not(.btn-outline).active,
.btn.yellow:not(.btn-outline):active,
.open>.btn.yellow:not(.btn-outline).dropdown-toggle {
    background-image: none
}
.btn.yellow:not(.btn-outline).disabled.focus,
.btn.yellow:not(.btn-outline).disabled:focus,
.btn.yellow:not(.btn-outline).disabled:hover,
.btn.yellow:not(.btn-outline)[disabled].focus,
.btn.yellow:not(.btn-outline)[disabled]:focus,
.btn.yellow:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.yellow:not(.btn-outline).focus,
fieldset[disabled] .btn.yellow:not(.btn-outline):focus,
fieldset[disabled] .btn.yellow:not(.btn-outline):hover {
    background-color: #c49f47;
    border-color: #c49f48
}
.btn.yellow:not(.btn-outline) .badge {
    color: #c49f47;
    background-color: #fff
}
.btn.btn-outline.yellow {
    border-color: #c49f47;
    color: #c49f47;
    background: 0 0
}
.btn.btn-outline.yellow.active,
.btn.btn-outline.yellow:active,
.btn.btn-outline.yellow:active:focus,
.btn.btn-outline.yellow:active:hover,
.btn.btn-outline.yellow:focus,
.btn.btn-outline.yellow:hover {
    border-color: #c49f47;
    color: #fff;
    background-color: #c49f48
}
.btn.purple:not(.btn-outline) {
    color: #fff;
    background-color: #8E44AD;
    border-color: #8E44AE
}
.btn.purple:not(.btn-outline).focus,
.btn.purple:not(.btn-outline):focus {
    color: #fff;
    background-color: #703688;
    border-color: #432051
}
.btn.purple:not(.btn-outline).active,
.btn.purple:not(.btn-outline):active,
.btn.purple:not(.btn-outline):hover,
.open>.btn.purple:not(.btn-outline).dropdown-toggle {
    color: #fff;
    background-color: #703688;
    border-color: #6a3381
}
.btn.purple:not(.btn-outline).active.focus,
.btn.purple:not(.btn-outline).active:focus,
.btn.purple:not(.btn-outline).active:hover,
.btn.purple:not(.btn-outline):active.focus,
.btn.purple:not(.btn-outline):active:focus,
.btn.purple:not(.btn-outline):active:hover,
.open>.btn.purple:not(.btn-outline).dropdown-toggle.focus,
.open>.btn.purple:not(.btn-outline).dropdown-toggle:focus,
.open>.btn.purple:not(.btn-outline).dropdown-toggle:hover {
    color: #fff;
    background-color: #5b2c6f;
    border-color: #432051
}
.btn.purple:not(.btn-outline).active,
.btn.purple:not(.btn-outline):active,
.open>.btn.purple:not(.btn-outline).dropdown-toggle {
    background-image: none
}
.btn.purple:not(.btn-outline).disabled.focus,
.btn.purple:not(.btn-outline).disabled:focus,
.btn.purple:not(.btn-outline).disabled:hover,
.btn.purple:not(.btn-outline)[disabled].focus,
.btn.purple:not(.btn-outline)[disabled]:focus,
.btn.purple:not(.btn-outline)[disabled]:hover,
fieldset[disabled] .btn.purple:not(.btn-outline).focus,
fieldset[disabled] .btn.purple:not(.btn-outline):focus,
fieldset[disabled] .btn.purple:not(.btn-outline):hover {
    background-color: #8E44AD;
    border-color: #8E44AE;
}
.btn.purple:not(.btn-outline) .badge {
    color: #8E44AD;
    background-color: #fff
}
.btn.btn-outline.purple {
    border-color: #8E44AD;
    color: #8E44AD;
    background: 0 0
}
.btn.btn-outline.purple.active,
.btn.btn-outline.purple:active,
.btn.btn-outline.purple:active:focus,
.btn.btn-outline.purple:active:hover,
.btn.btn-outline.purple:focus,
.btn.btn-outline.purple:hover {
    border-color: #8E44AD;
    color: #fff;
    background-color: #8E44AE
}
.btn.btn-outline.blue-bgcolor.active,
.btn.btn-outline.blue-bgcolor:active,
.btn.btn-outline.blue-bgcolor:active:focus,
.btn.btn-outline.blue-bgcolor:active:hover,
.btn.btn-outline.blue-bgcolor:focus,
.btn.btn-outline.blue-bgcolor:hover {
    border-color: #2196f2;
    color: #fff;
    background-color: #2196f3;
}
.btn.btn-outline.deepPink.active, .btn.btn-outline.deepPink:active, .btn.btn-outline.deepPink:active:focus, .btn.btn-outline.deepPink:active:hover, .btn.btn-outline.deepPink:focus, .btn.btn-outline.deepPink:hover {
    border-color: #e91e63;
    color: #fff;
    background-color: #e91e63;
}
.btn-circle {
    border-radius: 25px!important;
    overflow: hidden;
    outline: 0px !important;
}
.btn-circle-right {
    border-radius: 0 25px 25px 0!important
}
.btn-circle-left {
    border-radius: 25px 0 0 25px!important
}
.btn-circle-bottom {
    border-radius: 0 0 25px 25px!important
}
.btn-circle-top {
    border-radius: 25px 25px 0 0!important
}
.btn-icon-only {
    height: 34px;
    width: 34px;
    text-align: center;
    padding-left: 0;
    padding-right: 0
}
.btn-icon-only>[class^=icon-],
.btn-icon-only>i {
    text-align: center;
    margin-top: 2px
}
.btn-group.btn-group-circle>.btn:first-child {
    border-radius: 25px 0 0 25px!important
}
.btn-group.btn-group-circle>.btn:last-child {
    border-radius: 0 25px 25px 0!important
}
.btn-group.btn-group-devided>.btn {
    margin-right: 5px
}
.btn-group.btn-group-devided>.btn:last-child {
    margin-right: 0
}
.btn-group-vertical.btn-group-vertical-circle>.btn:first-child {
    border-radius: 25px 25px 0 0!important
}
.btn-group-vertical.btn-group-vertical-circle>.btn:last-child {
    border-radius: 0 0 25px 25px!important
}
.btn-group .btn {
    margin-right: 0;
}
.btn-group-vertical .btn {
	margin-bottom: 0;
}
.btn-group>.dropdown-menu,
.dropdown-menu {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .22)!important
}
.btn-group>.dropdown-menu {
    filter: alpha(opacity=0);
    margin-top: 0
}
.btn-group.dropup>.dropdown-menu {
    margin-top: 0;
    margin-bottom: 0
}
.btn-group.open>.dropdown-menu {
    transition: opacity 1.5s cubic-bezier(.23, 1, .32, 1) 0s;
    opacity: 1;
    filter: alpha(opacity=100)
}
.btn {
    font-size: 12px;
    transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    padding: 10px 14px 10px;
}
.btn-chatpane .btn,
.search-form .btn {
    box-shadow: none!important;
    overflow: visible
}
.btn.btn-link {
    box-shadow: none;
    color: #337ab7
}
.btn.btn-link:hover {
    text-decoration: none
}
.btn>i {
    margin-top: 0;
    margin-left: 3px;
    margin-right: 3px
}
.btn.btn-sm {
    font-size: 11px;
    padding: 6px 18px
}
.btn.btn-xs {
    font-size: 11px;
    padding: 3px 8px
}
.btn.btn-lg {
    font-size: 16px;
    padding: 12px 26px 10px
}
.btn.btn-icon-only {
    padding-left: 0;
    padding-right: 0
}
.btn.btn-icon-only>i {
    margin-left: 0;
    margin-right: 0
}
.btn.m-icon-big {
    padding: 9px 16px 8px
}
.btn.m-icon-big.m-icon-only {
    padding: 9px 8px 8px 0
}
.btn.m-icon-big [class^=m-icon-big-] {
    margin: 0 0 0 10px
}
.btn.m-icon-ony>i {
    margin-left: 0
}
.btn.m-icon {
    padding: 7px 14px
}
.btn.m-icon [class^=m-icon-] {
    margin: 4px 0 0 5px
}
.btn.m-icon.m-icon-only {
    padding: 7px 10px 7px 6px
}
.btn.btn-outline.green-bgcolor {
    border-color: #32c5d2;
    color: #32c5d2;
    background: 0 0
}
.btn.btn-outline.blue-bgcolor {
    border-color: #32c5d2;
    color: #32c5d2;
    background: 0 0;
}
.btn.btn-outline.deepPink {
    border-color: #e91e63 ;
    color: #e91e63 ;
    background: 0 0;
}
.btn.btn-outline.green-bgcolor.active,
.btn.btn-outline.green-bgcolor:active,
.btn.btn-outline.green-bgcolor:active:focus,
.btn.btn-outline.green-bgcolor:active:hover,
.btn.btn-outline.green-bgcolor:focus,
.btn.btn-outline.green-bgcolor:hover {
    border-color: #32c5d2;
    color: #FFF;
    background-color: #32c5d3
}
.btn.no-margin{
	margin: 0;
}
/************************************************************

					[18. LABEL ]

*************************************************************/

label {
    font-weight: 400
}
.label {
    text-shadow: none!important;
    color: #fff;
    font-family: Poppins,sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    padding: 8px 15px;
    background: #1ebdcc;
    line-height: 1;
    text-align: center;
}
.label.label-sm {
    font-size: 13px;
    padding: 2px 5px
}
h1 .label,
h2 .label,
h3 .label,
h4 .label,
h5 .label,
h6 .label {
    font-size: 75%
}
.label-event {
    background-color: #dc35a9 !important
}
.label-event[href]:focus,
.label-event[href]:hover {
    background-color: #9ca8bb !important
}
.label-primary {
    background: linear-gradient(45deg,#4099ff,#73b4ff) !important;
}
.label-primary[href]:focus,
.label-primary[href]:hover {
    background-color: #286090 !important
}
.label-success {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5) !important;
}
.label-success[href]:focus,
.label-success[href]:hover {
    background-color: #27a4b0 !important
}
.label-info {
    background-color: #659be0 !important
}
.label-info[href]:focus,
.label-info[href]:hover {
    background-color: #3a80d7 !important
}
.label-warning {
    background: linear-gradient(45deg,#FFB64D,#ffcb80) !important;
}
.label-warning[href]:focus,
.label-warning[href]:hover {
    background-color: #c29d0b !important
}
.label-danger {
   background: linear-gradient(45deg,#FF5370,#ff869a) !important;
}
.label-danger[href]:focus,
.label-danger[href]:hover {
    background-color: #e73d4a !important
}
.label.label-icon {
    padding: 4px 0 4px 4px;
    margin-right: 2px;
    text-align: center!important
}
.label.label-icon>i {
    font-size: 12px;
    text-align: center!important
}
.ie8 .label.label-icon,
.ie9 .label.label-icon {
    padding: 3px 0 3px 3px
}
.label-menu {
    margin-right: 30px;
    font-size: 10px;
    border-radius: 60px;
    padding: 6px 8px;
    min-width: 30px;
    height: 20px;
    float: right;
}

.label.label-sm {
    font-size: 10px;
    font-weight: 600;
    padding: 6px 6px;
    margin-right: 5px;
}
.label.label-icon {
    padding: 4px 0 4px 4px!important;
    font-size: 12px!important
}
.note p,
.page-loading,
.panel .panel-body {
    font-size: 14px
}
.ie8 .label.label-icon,
.ie9 .label.label-icon {
    padding: 3px 0 3px 3px!important
}
.sub-title {
    border-bottom: 1px solid rgba(204, 204, 204, 0.35);
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #2c3e50;
}
/***************************************************************

					[19. CALENDAR ]

****************************************************************/

.calendar-block .cal1 .clndr .clndr-controls .month {
    color: #fff;
}
.calendar-block .cal1 .clndr .clndr-controls .month {
    display: inline-block;
    width: 190px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    padding: 5px 5px;
    position: relative;
    top: 2px;
}
.calendar-block .cal1 .clndr .clndr-controls {
    -webkit-border-radius: 5px 5px 0px 0px;
    -moz-border-radius: 5px 5px 0px 0px;
    border-radius: 5px 5px 0px 0px;
    display: block !important;
    position: relative;
    margin-bottom: 10px;
    text-align: center;
    background: #32c5d2;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -15px;
    padding: 45px 20px;
    width: auto !important;
    color: #fff;
}
.calendar-block .cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button,
.calendar-block .cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button {
    color: #fff;
}
.calendar-block .cal1 .clndr .clndr-controls .month span {
    display: block;
    font-size: 30px;
    padding: 0px 10px;
    margin-bottom: 0;
}
.clndr-next-button,
.clndr-previous-button {
    position: relative;
    top: -30px;
}
.calendar-block .day-contents:hover {
    width: 30px;
    background: #32c5d2;
    cursor: pointer;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin: 0 auto;
}
.today .day-contents {
    width: 30px;
    background: #32c5d2;
    cursor: pointer;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin: 0 auto;
}
.fc-state-default {
    border: 1px solid #eee;
    background: transparent !important;
    color: #7e869d;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0;
}
.fc-state-active {
    background: #a389D4 !important;
    color: #fff;
}
.fc-day-grid-event {
    padding: 5px 1px;
    border: 0;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
}
.fc .fc-today-button {
    margin-left: 12px;
}
/***************************************************************

					[20. DROP DOWN STYLE ]

****************************************************************/

.dropdown.open>.dropdown-toggle,
.dropup.open>.dropdown-toggle {
    border-color: #ddd
}
.dropdown-menu {
    left: auto;
    right: 0;
    min-width: 175px;
    position: absolute;
    z-index: 1000;
    display: none;
    float: left;
    list-style: none;
    text-shadow: none;
    padding: 0;
    background-color: #fff;
    font-family: Poppins,sans-serif;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px
}
.navbar-nav .dropdown-menu {
	position: absolute;
}
.dropdown-menu li.divider {
    background: #f1f3f6
}
.dropdown-menu li.header {
    padding: 8px 14px 2px
}
.dropdown-menu>li>a {
    padding: 8px 16px;
    color: #6f6f6f;
    text-decoration: none;
    display: block;
    clear: both;
    font-weight: 300;
    line-height: 18px;
    white-space: nowrap
}
.dropdown-menu>li>a>[class*=" fa-"],
.dropdown-menu>li>a>[class^=fa-] {
    color: #888
}
.dropdown-menu>li>a>[class*=" icon-"],
.dropdown-menu>li>a>[class^=icon-] {
    color: #666
}
.dropdown-menu>li>a>[class*=" glyphicon-"],
.dropdown-menu>li>a>[class^=glyphicon-] {
    color: #888
}
.dropdown-menu>li.active:hover>a,
.dropdown-menu>li.active>a,
.dropdown-menu>li:hover>a {
    text-decoration: none;
    background-image: none;
    background-color: #f6f6f6;
    color: #555;
    filter: none
}
.dropdown-menu.bottom-up {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px
}
.dropdown-menu.bottom-up:after,
.dropdown-menu.bottom-up:before {
    display: none
}
.dropdown-menu>li:first-child:hover>a {
    border-radius: 2px 2px 0 0
}
.dropdown-menu>li:last-child:hover>a {
    border-radius: 0 0 2px 2px
}
.dropdown-hover:hover>.dropdown-menu {
    display: block
}
.btn-group>.dropdown-menu.pull-left:before,
.dropdown-toggle>.dropdown-menu.pull-left:before,
.dropdown>.dropdown-menu.pull-left:before {
    left: auto;
    right: 9px
}
.btn-group>.dropdown-menu.pull-left:after,
.dropdown-toggle>.dropdown-menu.pull-left:after,
.dropdown>.dropdown-menu.pull-left:after {
    left: auto;
    right: 10px
}
.btn-group>.dropdown-menu.pull-right:before,
.dropdown-toggle>.dropdown-menu.pull-right:before,
.dropdown>.dropdown-menu.pull-right:before {
    left: auto;
    right: 9px
}
.btn-group>.dropdown-menu.pull-right:after,
.dropdown-toggle>.dropdown-menu.pull-right:after,
.dropdown>.dropdown-menu.pull-right:after {
    left: auto;
    right: 10px
}
.btn-group.dropup>.dropdown-menu,
.dropdown-toggle.dropup>.dropdown-menu,
.dropdown.dropup>.dropdown-menu {
    margin-top: 0;
    margin-bottom: 10px
}
.btn-group.dropup>.dropdown-menu:after,
.btn-group.dropup>.dropdown-menu:before,
.dropdown-toggle.dropup>.dropdown-menu:after,
.dropdown-toggle.dropup>.dropdown-menu:before,
.dropdown.dropup>.dropdown-menu:after,
.dropdown.dropup>.dropdown-menu:before {
    display: none!important
}
.dropdown-toggle:after {
    display: none
}
.dropdown-submenu {
    position: relative
}
.dropdown-submenu>.dropdown-menu {
    top: 5px;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px
}
.dropdown-submenu>a:after {
    position: absolute;
    display: inline-block;
    font-size: 14px;
    right: 7px;
    top: 7px;
    font-family: FontAwesome;
    height: auto;
    content: "\f105";
    font-weight: 300
}
.dropdown-submenu:hover>.dropdown-menu {
    display: block
}
.dropdown-submenu:hover>a:after {
    border-left-color: #fff
}
.dropdown-submenu.pull-left {
    float: none
}
.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px
}
.dropup .dropdown-submenu>.dropdown-menu {
    top: auto;
    bottom: 0;
    margin-top: 0;
    margin-bottom: -2px
}
.nav.pull-right>li>.dropdown-menu,
.nav>li>.dropdown-menu.pull-right {
    right: 0;
    left: auto
}
.nav.pull-right>li>.dropdown-menu:before,
.nav>li>.dropdown-menu.pull-right:before {
    right: 12px;
    left: auto
}
.nav.pull-right>li>.dropdown-menu:after,
.nav>li>.dropdown-menu.pull-right:after {
    right: 13px;
    left: auto
}
.nav.pull-right>li>.dropdown-menu .dropdown-menu,
.nav>li>.dropdown-menu.pull-right .dropdown-menu {
    right: 100%;
    left: auto;
    margin-right: -1px;
    margin-left: 0
}
.dropdown-content {
    padding: 10px
}
.dropdown-content form {
    margin: 0
}
.dropdown.inline .dropdown-menu {
    display: inline-block;
    position: relative
}
.dropdown-menu {
    border: 0!important;
    border-radius: 2px!important;
    margin: 0
}
.dropdown-menu:after,
.dropdown-menu:before {
    display: none!important
}
.dropdown-menu-footer {
    padding: 10px;
    border-top: 1px solid #EFF2F6;
    text-align: center;
    float: left;
    width: 100%;
}
.dropdown-menu-footer {
    padding: 10px;
    border-top: 1px solid #EFF2F6;
    text-align: center;
    float: left;
    width: 100%;
    background-color: #F3F7F9;
}
.dropdown-menu-footer a {
    color: #a3afb7;
    transition: 0.5s;
}
.dropdown-menu-footer a:hover {
    color: #18B2BF;
    text-decoration: none;
}
/***************************************************************

					[21. CHECKBOX & RADIO ]

****************************************************************/

.rt-chkbox,
.rt-radio {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s
}
.rt-chkbox.rt-chkbox-disabled,
.rt-chkbox.rt-radio-disabled,
.rt-radio.rt-chkbox-disabled,
.rt-radio.rt-radio-disabled {
    opacity: .6;
    filter: alpha(opacity=60)
}
.rt-chkbox>input,
.rt-radio>input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    filter: alpha(opacity=0)
}
.rt-chkbox>span,
.rt-radio>span {
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background: #E6E6E6
}
.rt-chkbox>span:after,
.rt-radio>span:after {
    content: '';
    position: absolute;
    display: none
}
.rt-chkbox-list .rt-chkbox,
.rt-chkbox-list .rt-radio,
.rt-chkbox>input:checked~span:after,
.rt-radio-list .rt-chkbox,
.rt-radio-list .rt-radio,
.rt-radio>input:checked~span:after {
    display: block
}
.rt-chkbox:hover>input:not([disabled])~span,
.rt-chkbox>input:focus~span,
.rt-radio:hover>input:not([disabled])~span,
.rt-radio>input:focus~span {
    background: #d9d9d9;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s
}
.rt-chkbox:hover>input:not([disabled]):checked~span,
.rt-chkbox>input:checked~span,
.rt-radio:hover>input:not([disabled]):checked~span,
.rt-radio>input:checked~span {
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background: #d9d9d9
}
.rt-chkbox.rt-chkbox-outline:hover>input:not([disabled]):checked~span,
.rt-chkbox.rt-chkbox-outline:hover>input:not([disabled])~span,
.rt-chkbox.rt-chkbox-outline>input:checked~span,
.rt-chkbox.rt-chkbox-outline>input:focus~span,
.rt-chkbox.rt-chkbox-outline>span,
.rt-chkbox.rt-radio-outline:hover>input:not([disabled]):checked~span,
.rt-chkbox.rt-radio-outline:hover>input:not([disabled])~span,
.rt-chkbox.rt-radio-outline>input:checked~span,
.rt-chkbox.rt-radio-outline>input:focus~span,
.rt-chkbox.rt-radio-outline>span,
.rt-radio.rt-chkbox-outline:hover>input:not([disabled]):checked~span,
.rt-radio.rt-chkbox-outline:hover>input:not([disabled])~span,
.rt-radio.rt-chkbox-outline>input:checked~span,
.rt-radio.rt-chkbox-outline>input:focus~span,
.rt-radio.rt-chkbox-outline>span,
.rt-radio.rt-radio-outline:hover>input:not([disabled]):checked~span,
.rt-radio.rt-radio-outline:hover>input:not([disabled])~span,
.rt-radio.rt-radio-outline>input:checked~span,
.rt-radio.rt-radio-outline>input:focus~span,
.rt-radio.rt-radio-outline>span {
    border: 1px solid #d9d9d9;
    background: 0 0
}
.rt-chkbox>span:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #666;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg)
}
.rt-chkbox>input:disabled~span:after {
    border-color: #666
}
.rt-chkbox-list,
.rt-radio-list {
    padding: 10px 0
}
.form-horizontal .form-group .rt-chkbox-list,
.form-horizontal .form-group .rt-radio-list {
    padding-top: 0
}
.rt-chkbox-inline,
.rt-radio-inline {
    padding: 10px 0
}
.form-horizontal .form-group .rt-chkbox-inline,
.form-horizontal .form-group .rt-radio-inline {
    padding-top: 8px
}
.rt-chkbox-inline .rt-chkbox,
.rt-chkbox-inline .rt-radio,
.rt-radio-inline .rt-chkbox,
.rt-radio-inline .rt-radio {
    display: inline-block;
    margin-right: 15px
}
.rt-chkbox-inline .rt-chkbox:last-child,
.rt-chkbox-inline .rt-radio:last-child,
.rt-radio-inline .rt-chkbox:last-child,
.rt-radio-inline .rt-radio:last-child {
    margin-right: 0
}
td>.rt-chkbox.rt-chkbox-single,
td>.rt-radio.rt-radio-single,
th>.rt-chkbox.rt-chkbox-single,
th>.rt-radio.rt-radio-single {
    right: -5px
}
.rt-radio>span,
.timeline .timeline-badge-userpic img {
    -webkit-border-radius: 50%!important;
    -moz-border-radius: 50%!important
}
.rt-radio>span {
    -ms-border-radius: 50%!important;
    -o-border-radius: 50%!important;
    border-radius: 50%!important
}
.rt-radio>span:after {
    left: 6px;
    top: 6px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #666
}
.rt-radio>input:disabled~span:after {
    background: #666
}
/**********************************************************

					[22. ICON BUTTON ]

***********************************************************/

.icon-btn {
    height: 60px;
    min-width: 80px;
    margin: 5px 5px 0 0;
    padding: 12px 0 0;
    background-color: #fafafa;
    background-image: none;
    display: inline-block;
    color: #646464;
    text-shadow: none;
    text-align: center;
    cursor: pointer;
    position: relative
}
.icon-btn,
.icon-btn:hover {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease
}
.icon-btn,
.ie8 .icon-btn:hover {
    filter: none
}
.loading-message,
.page-loading {
    min-width: 125px;
    vertical-align: middle
}
.icon-btn:hover {
    border-color: #999;
    color: #444;
    text-shadow: 0 1px 0 #fff;
    transition: all .3s ease;
    text-decoration: none
}
.icon-btn:hover>.badge {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}
.icon-btn>.badge {
    position: absolute;
    font-size: 11px;
    font-weight: 300;
    top: -5px;
    right: -5px;
    padding: 3px 6px;
    color: #fff;
    text-shadow: none;
    border-width: 0;
    border-style: solid;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px
}
.icon-btn>i {
    font-size: 18px
}
.icon-btn {
    border: 0;
    transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1)
}
.icon-btn>div {
    margin-top: 5px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    color: #565d66
}
/************************************************************

			[23. VALIDATION ICONS ]

*************************************************************/

.input-icon {
    position: relative;
    left: 0
}
.input-icon>.form-control {
    padding-left: 33px
}
.input-group .input-icon>.form-control {
    border-radius: 2px 0 0 2px
}
.input-group .input-icon.right>.form-control,
.tabs-right.nav-tabs>li>a:focus,
.tabs-right.nav-tabs>li>a:hover {
    -webkit-border-radius: 0 2px 2px 0;
    -moz-border-radius: 0 2px 2px 0;
    -ms-border-radius: 0 2px 2px 0;
    -o-border-radius: 0 2px 2px 0
}
.input-icon>i {
    color: #ccc;
    display: block;
    position: absolute;
    margin: 11px 2px 4px 10px;
    z-index: 3;
    width: 16px;
    font-size: 16px;
    text-align: center;
    left: 0
}
.modal .input-icon>i {
    z-index: 10055
}
.has-success .input-icon>i {
    color: #36c6d3
}
.has-warning .input-icon>i {
    color: #F1C40F
}
.has-error .input-icon>i {
    color: #ed6b75
}
.input-icon.right {
    left: auto;
    right: 0
}
.input-icon.right>.form-control {
    padding-right: 33px;
    padding-left: 12px
}
.input-group .input-icon.right>.form-control {
    border-radius: 0 2px 2px 0
}
.input-icon.right>i {
    left: auto;
    right: 8px;
    float: right
}
.input-icon.input-icon-lg>i {
    margin-top: 16px
}
.input-icon.input-icon-sm>i {
    margin-top: 8px;
    font-size: 13px
}
/****************************************************************

				[24. NOTE ]

*****************************************************************/

.note {
    margin: 0 0 20px;
    padding: 15px 30px 15px 15px
}
.note h1,
.note h2,
.note h3,
.note h4,
.note h5,
.note h6 {
    margin-top: 0
}
.note h1 .close,
.note h2 .close,
.note h3 .close,
.note h4 .close,
.note h5 .close,
.note h6 .close {
    margin-right: -10px
}
.note p {
    margin: 0
}
.note p:last-child {
    margin-bottom: 0
}
.note.note-default {
    border-color: #b0c1d2;
    color: #000
}
.note.note-default.note-bordered {
    background-color: #eef1f5;
    border-color: #c0cedb
}
.note.note-default.note-shadow {
    background-color: #f1f4f7;
    border-color: #d1dbe4;
    box-shadow: 5px 5px rgba(212, 221, 230, .2)
}
.note.note-success {
    background-color: #c0edf1;
    border-color: #58d0da;
    color: #000
}
.note.note-success.note-bordered {
    background-color: #a7e6ec;
    border-color: #6dd6df
}
.note.note-success.note-shadow {
    background-color: #abe7ed;
    border-color: #81dbe3;
    box-shadow: 5px 5px rgba(134, 221, 228, .2)
}
.note.note-info {
    background-color: #f5f8fd;
    border-color: #8bb4e7;
    color: #010407
}
.note.note-info.note-bordered {
    background-color: #dbe8f8;
    border-color: #a0c2ec
}
.note.note-info.note-shadow {
    background-color: #e0ebf9;
    border-color: #b5cff0;
    box-shadow: 5px 5px rgba(185, 210, 241, .2)
}
.note.note-warning {
    background-color: #faeaa9;
    border-color: #f3cc31;
    color: #000
}
.note.note-warning.note-bordered {
    background-color: #f8e38c;
    border-color: #f4d249
}
.note.note-warning.note-shadow {
    background-color: #f9e491;
    border-color: #f6d861;
    box-shadow: 5px 5px rgba(246, 217, 102, .2)
}
.note.note-danger {
    background-color: #fef7f8;
    border-color: #f0868e;
    color: #210406
}
.note.note-danger.note-bordered {
    background-color: #fbdcde;
    border-color: #f39da3
}
.note.note-danger.note-shadow {
    background-color: #fbe1e3;
    border-color: #f6b3b8;
    box-shadow: 5px 5px rgba(246, 184, 189, .2)
}
.note {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    border: 0
}
/****************************************************************

				[25. PAGINATION ]

*****************************************************************/

.pagination {
    margin: 10px 0
}
.pagination.pagination-circle>li:first-child>a {
    border-radius: 25px 0 0 25px!important
}
.pagination.pagination-circle>li:last-child>a {
    border-radius: 0 25px 25px 0!important
}
/****************************************************************

				[26. ACCORDION ]

*****************************************************************/

.accordion .card-header:after {
    font-family: 'FontAwesome';
    content: "\f068";
    float: right;
}
.accordion .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\f067";
}
/****************************************************************

					[27. TAB ]

*****************************************************************/

.tab-pane>p:last-child {
    margin-bottom: 0
}
.tabs-reversed>li {
    float: right;
    margin-right: 0
}
.tabs-reversed>li>a {
    margin-right: 0
}
.customtab.nav-tabs .nav-link.active,  .customtab.nav-tabs .nav-link.active:focus {
    border-bottom: 0px solid #36c6d3;
    background-color: rgb(102, 115, 252) !important;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .2), 0 13px 24px -11px rgb(102, 115, 252);
    color: #fff;
    border-radius: 30px;
}
.customtab.nav-tabs .nav-link {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    color: #999;
}
.btmBorder{
	border-bottom: 0px !important;
}
.customtab {
    border-bottom: 2px solid #f7fafc;
}
nav-tabs>li {
    float: left;
    margin-bottom: -1px;
}
.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
}
.nav-pills>li>a, .nav-tabs>li>a {
    font-size: 14px;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
    position: relative;
    display: block;
    padding: 10px 15px;
    border-radius: 2px 2px 0 0;
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}
.nav-tabs>li a.active, .nav-tabs>li a.active:focus, .nav-tabs>li a.active:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border-bottom-color: transparent;
}
/****************************************************************

					[28. STATE OVERVIEW ]

*****************************************************************/

.state-overview h1.sbold,
.state-overview p.sbold {
    float: left;
}
.state-overview p {
    float: left;
    width: 100%;
}
.state-overview .purple {
    background: #a979d1;
    color: #fff;
}
.state-overview .orange {
    background: #E67D21;
    color: #fff;
}
.state-overview .blue {
    background: #55ACEF;
    color: #fff;
}
.state-overview .blue {
    background: #55ACEF;
    color: #fff;
}
.state-overview .green {
    background: #15A087;
    color: #fff;
}
.state-overview .symbol {
    width: 30%;
    padding: 50px 15px;
}
.state-overview .symbol,
.state-overview .value {
    display: inline-block;
    text-align: center;
}
.state-overview .symbol i {
    font-size: 25px;
}
.fa-send:before,
.fa-paper-plane:before {
    content: "\f1d8";
}
.state-overview .value.white {
    border-color: rgba(255, 255, 255, .2);
    text-align: left;
}
.state-overview .value {
    width: 68%;
    margin-top: 30px;
    border-left: 1px solid;
    padding-left: 10%;
}
.state-overview .value {
    float: right;
    text-align: left;
}
.state-overview .symbol,
.state-overview .value {
    display: inline-block;
    text-align: center;
}
.state-overview .value h1 {
    font-weight: 300;
}
.state-overview .value h1,
.state-overview .value p {
    margin: 0;
    padding: 0;
}
.state-overview .value h1,
.state-overview .value p {
    margin: 0;
    padding-bottom: 15px;
}
.bg-blue {
    background-color: #389af0;
}
.info-box {
	border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    min-height: 100px;
    background: #fff;
    width: 100%;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, .1);
    margin-bottom: 20px;
    padding: 15px;
}
.info-box small {
    font-size: 14px;
}
.info-box .progress {
    background: rgba(0, 0, 0, .2);
    margin: 5px -10px 5px 0;
    height: 2px;
}
.info-box .progress, .info-box .progress .progress-bar {
    border-radius: 0;
}
.info-box .progress .progress-bar {
    background: #fff;
}
.info-box-icon {
    float: left;
    height: 70px;
    width: 70px;
    text-align: center;
    font-size: 30px;
    line-height: 74px;
    background: rgba(0, 0, 0, .2);
    border-radius: 100%}
.info-box-icon.push-bottom {
    margin-top: 20px;
}
.info-box-icon>img {
    max-width: 100%}
.info-box-content {
    padding: 10px 10px 10px 0;
    margin-left: 90px;
}
.info-box-number {
    font-weight: 300;
    font-size: 21px;
}
.info-box-text, .progress-description {
    display: block;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: 400;
}
.progress-description {
    margin: 0;
}
/********************************************************************

					[29. BORDER BOX ]

*********************************************************************/

.borderBox.light.bordered {
    border: 1px solid #e7ecf1!important;
}
.borderBox.light {
    padding: 12px 20px 15px;
    background-color: #fff;
}
.borderBox {
    margin-top: 0;
    margin-bottom: 25px;
    padding: 0;
    border-radius: 2px;
    box-shadow: 0 2px 3px 2px rgba(0, 0, 0, .03);
}
.borderBox.light.bordered>.borderBox-title {
    border-bottom: 1px solid #eef1f5;
}
.borderBox.light>.borderBox-title {
    padding: 0;
    min-height: 48px;
}
.borderBox.bordered>.borderBox-title {
    border-bottom: 0;
}
.borderBox>.borderBox-title {
    border-bottom: 1px solid #eee;
    padding: 0;
    margin-bottom: 10px;
    min-height: 41px;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    -ms-border-radius: 2px 2px 0 0;
    -o-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}
.borderBox>.borderBox-title:after,
.borderBox>.borderBox-title:before {
    content: " ";
    display: table;
}
.borderBox.light>.borderBox-title>.caption {
    color: #666;
    padding: 10px 0;
}
.borderBox>.borderBox-title>.caption {
    float: left;
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    padding: 10px 0;
}
.borderBox.light>.borderBox-title>.caption>.caption-subject {
    font-size: 16px;
}
.borderBox>.borderBox-title>.nav-tabs {
    background: 0 0;
    margin: 1px 0 0;
    float: right;
    display: inline-block;
    border: 0;
}
.borderBox.light>.borderBox-title>.nav-tabs>li {
    margin: 0;
    padding: 0;
}
.borderBox>.borderBox-title>.nav-tabs>li {
    background: 0 0;
    margin: 0;
}
.borderBox.light>.borderBox-title>.nav-tabs>li>a {
    margin: 0;
    padding: 12px 13px 13px;
    font-size: 13px;
    color: #666;
}
.borderBox>.borderBox-title>.nav-tabs>li>a {
    background: 0 0;
    margin: 5px 0 0 1px;
    border: 0;
    padding: 8px 10px;
    color: #fff;
}
.borderBox.light>.borderBox-title>.nav-tabs>li.active>a,
.borderBox.light>.borderBox-title>.nav-tabs>li:hover>a {
    margin: 0;
    background: 0 0;
    color: #333;
}
.borderBox.light>.borderBox-title>.nav-tabs>li>a {
    margin: 0;
    padding: 12px 13px 13px;
    font-size: 13px;
    color: #666;
}
/***************************************************************

				[30. RATING BUTTON ]

****************************************************************/

.rating {
    unicode-bidi: bidi-override;
    direction: rtl;
}
.rating span.star,
.rating span.star {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    font-size: 22px;
}
.rating span.star:before,
.rating span.star:before {
    content: "\f006";
    padding-right: 5px;
    color: #BEC3C7;
}
.rating span.star:hover,
.rating span.star:hover {
    cursor: pointer;
}
.rating span.star,
.rating span.star {
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    font-size: 22px;
}
.rating span.star:hover:before,
.rating span.star:hover:before,
.rating span.star:hover ~ span.star:before,
.rating span.star:hover ~ span.star:before {
    content: "\f005";
    color: #986cbc;
}
.rating span.star:before,
.rating span.star:before {
    content: "\f006";
    padding-right: 5px;
    color: #BEC3C7;
}
/*********************************************************************

					[31. TO DO LIST ]

**********************************************************************/

.todo-title {
    margin-right: 25px;
    padding-top: 5px;
}
.to-do-list {
    padding-left: 0;
    margin-top: -10px;
    font-size: 12px;
    min-height: 311px;
    float: left;
    width: 100%;
}
.to-do-list li {
    padding: 17px 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    cursor: move;
    list-style: none;
    font-size: 14px;
    background: #fff;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
}
.to-do-list li p {
    margin: 0px;
}
.todo-actionlist {
    position: absolute;
    right: -5px;
    top: 22px;
}
.todo-actionlist a {
    height: 24px;
    width: 24px;
    display: inline-block;
    float: left;
}
.todo-actionlist a i {
    height: 24px;
    width: 24px;
    display: inline-block;
    text-align: center;
    line-height: 24px;
    color: #ccc;
}
.todo-actionlist a:hover i {
    color: #666;
}
.todo-done i {
    font-size: 14px;
}
.todo-remove i {
    font-size: 10px;
}
.line-through {
    text-decoration: line-through;
}
.todo-action-bar {
    margin-top: 20px;
}
.drag-marker {
    height: 17px;
    display: block;
    float: left;
    width: 7px;
    position: relative;
    top: 6px;
}
.drag-marker i {
    height: 2px;
    width: 2px;
    display: block;
    background: #ccc;
    box-shadow: 5px 0 0 0px #ccc, 0px 5px 0 0px #ccc, 5px 5px 0 0px #ccc, 0px 10px 0 0px #ccc, 5px 10px 0 0px #ccc, 0px 15px 0 0px #ccc, 5px 15px 0 0px #ccc;
    -webkit-box-shadow: 5px 0 0 0px #ccc, 0px 5px 0 0px #ccc, 5px 5px 0 0px #ccc, 0px 10px 0 0px #ccc, 5px 10px 0 0px #ccc, 0px 15px 0 0px #ccc, 5px 15px 0 0px #ccc;
    -moz-box-shadow: 5px 0 0 0px #ccc, 0px 5px 0 0px #ccc, 5px 5px 0 0px #ccc, 0px 10px 0 0px #ccc, 5px 10px 0 0px #ccc, 0px 15px 0 0px #ccc, 5px 15px 0 0px #ccc;
}
.to-do-list li .todo-check input[type=checkbox] {
    visibility: hidden;
}
.inbox-small-cells .todo-check input[type=checkbox] {
    visibility: hidden;
}
.todo-check input[type=checkbox] {
    visibility: hidden;
}
.todo-check {
    width: 20px;
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 5px;
}
.todo-check label {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: #ccc 1px solid;
}
.todo-check label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 13px;
    height: 8px;
    background: transparent;
    top: 3px;
    left: 3px;
    border: 3px solid #cfcfcf;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.todo-checklabel:hover::after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.3;
}
.todo-check input[type=checkbox]:checked+label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}
.todo-entry {
    float: left;
    width: 88%;
}
.StrikeThrough {
    text-decoration: line-through;
}
/***********************************************************************

						[32. BADGE ]

***********************************************************************/

.badge {
    font-size: 11px!important;
    height: 18px;
    color: #fff;
    -webkit-border-radius: 12px!important;
    -moz-border-radius: 12px!important;
    border-radius: 12px!important;
    text-shadow: none!important;
    text-align: center
}
.list-group-item>.badge {
    float: right;
    margin-left: 5px;
}
.badge.badge-roundless {
    -webkit-border-radius: 0!important;
    -moz-border-radius: 0!important;
    border-radius: 0!important
}
.badge.badge-empty {
    display: inline-block;
    padding: 0;
    min-width: 8px;
    height: 8px;
    width: 8px
}
.badge-default {
    background-color: #bac3d0
}
.badge-primary {
    background-color: #337ab7
}
.badge-info {
    background-color: #659be0
}
.badge-success {
    background-color: #36c6d3
}
.badge-danger {
    background-color: #ed6b75
}
.badge-warning {
    background-color: #F1C40F
}
.nav.nav-pills>li>a>.badge,
.nav.nav-stacked>li>a>.badge {
    margin-top: -2px
}
.dropdown-menu>li>a>.badge {
    position: absolute;
    margin-top: 1px;
    right: 3px;
    display: inline;
    padding: 4px 6px 2px
}
.dropdown-menu.badge-roundless {
    -webkit-border-radius: 0!important;
    -moz-border-radius: 0!important;
    border-radius: 0!important
}
.badge {
    padding: 4px 6px 2px
}
/****************************************************************************

						[33. SCROLL TO TOP ]

*****************************************************************************/

.scroll-to-top {
    padding: 11px;
    border-radius: 4px;
    background: #1c262f;
    text-align: center;
    position: fixed;
    bottom: 10px;
    z-index: 10001;
    display: none;
    right: 10px
}
.scroll-to-top>i {
    display: inline-block;
    color: #ffffff;
    font-size: 20px;
    opacity: .6;
    filter: alpha(opacity=60)
}
.scroll-to-top:hover {
    cursor: pointer
}
.scroll-to-top:hover>i {
    opacity: 1;
    filter: alpha(opacity=100)
}
/*********************************************************************

					[34. FOOTER ]

**********************************************************************/

.page-footer .page-footer-inner {
    color: #98a6ba;
}
.page-footer-fixed .page-footer {
    background-color: #28303b;
}
.page-footer-fixed.page-footer-fixed-mobile .page-container {
    margin-bottom: 20px !important
}
.page-footer {
    padding: 20px 5px;
    font-size: 13px;
    background-color: #222c3c;
}
.page-footer:after,
.page-footer:before {
    content: " ";
    display: table
}
.page-footer .page-footer-inner {
    float: left;
    width: 100%;
    text-align: center;
}
.page-footer-fixed.page-footer-fixed-mobile .page-footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 0
}
.page-footer-fixed.page-footer-fixed-mobile.sidemenu-container-fixed .page-footer {
    margin-left: 0 !important
}
.mat-card:not([class*=mat-elevation-z]) {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

/*********************************************************************

					[35. FEED ]

**********************************************************************/

.feedBody {
    border-left: 1px solid #d6d6d6;
    margin-left: 30px;
    padding: 0;
}
.col-auto {
    padding-left: 15px;
    padding-right: 15px;
}
.feedLblStyle {
	font-weight: bold;
	padding: 0px 7px 0px 7px;
	border-radius: 10px;
}
.lblFileStyle {
	color: red;
    border: 1px solid red;
}
.lblTaskStyle{
	color: #2ed8b6;
    border: 1px solid #2ed8b6;
}
.lblCommentStyle{
	color: #4099ff;
    border: 1px solid #4099ff;
}
.lblReplyStyle{
	color: #f15317;
    border: 1px solid #f15317;
}
.feedBody li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 25px;
}
.feedBody li .feed-user-img {
    position: absolute;
    left: -20px;
    top: -10px;
}
.feedBody li .feed-user-img img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.feedBody li.active-feed .feed-user-img:after {
    border-color: #2ed8b6;
}
.feedBody li .feed-user-img:after {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    border: 3px solid transparent;
    border-radius: 50%;
}
.feedBody li h6 {
    line-height: 1.5;
    cursor: pointer;
}
.text-muted {
    color: #919aa3!important;
    margin-bottom: 10px;
}
.img-100 {
    width: 100px;
}
.feedBody li h6 {
    line-height: 1.5;
    cursor: pointer;
}
.feedBody li.active-feed .feed-user-img:after {
    border-color: #2ed8b6;
}
